import { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Template from "../../template/Template";
import TableDataSimple from "../../components/Tables/TableDataSimple";
import SimpleBackdrop from '../../components/Loading/SimpleBackdrop';
import { AlertInfo } from '../../components/Alerts/Alert';
import { api } from '../../services/api';
import isPermission from '../../services/permission';

export default function Professores() {
    const [infoLogin] = useState(JSON.parse(sessionStorage.getItem('infoLogin')));
    const [openLoad, setOpenLoad] = useState(false);
    const [rows, setRows] = useState([]);
    const navigate = useNavigate();
    const columns = [
        {
            id: 'nome_professor',
            numeric: false,
            label: 'NOME PROFESSOR(A)',
        },
        {
            id: 'email',
            numeric: false,
            disablePadding: false,
            label: 'EMAIL',
        },
        {
            id: 'telefone_1',
            numeric: false,
            disablePadding: false,
            label: 'TELEFONE 1',
        },
        {
            id: 'telefone_2',
            numeric: false,
            disablePadding: false,
            label: 'TELEFONE 2',
        }
    ];

    function handleClick(row) {
        if(isPermission(7) && row.id_professor){
            navigate(`/cadastroprofessor?id=${row.id_professor}`);
        }
    }

    async function loadData() {
        setOpenLoad(true);
        const id_escola_professor = infoLogin.id_escola_usuario;
        try {
            const response = await api.post(`/professores`, {id_escola_professor});
            if(response && response.status === 200 && response.data){
                if(response.data.error) AlertInfo("error", response.data.message);
                else setRows(response.data);
            }
        } catch (error) {
            console.log(error);
            AlertInfo("error", 'NÃO POSSIVEL BUSCAR DADOS');
        }
        setOpenLoad(false);
    }

    useEffect(() => {
        loadData();
        // eslint-disable-next-line
    }, []);

    return(
        <Template menuActive="professores">
            <Grid container spacing={1}>
                <Grid item xs={12} sm={2}>
                    {isPermission(6) && (
                        <Link to="/cadastroprofessor">
                            <Button 
                                variant="contained"
                                fullWidth
                            >
                                NOVO PROFESSOR(A)
                            </Button>
                        </Link>
                    )}
                </Grid>
                <Grid item xs={12}>
                    <TableDataSimple
                        label="Lista de Professores"
                        rows={rows}
                        columns={columns} 
                        handleClick={handleClick}
                    />
                </Grid>
            </Grid>

            <SimpleBackdrop open={openLoad} />
        </Template>
    );
}