import { useEffect, useState } from "react";
import { ListItem } from "@mui/material";
import Button from '@mui/material/Button';
import DrawerApp from "../../components/Drawer/DrawerApp";
import SelectSimple from '../../components/Selects/SelectSimple';
import Input from '../../components/Inputs/Input';
import { api } from '../../services/api';

export default function DrawerBuscaAlunos({ open, setOpen, setRows, allRows }) {
    const [busca, setBusca] = useState("id_turma_aluno");

    const [id_turma_aluno, setIdTurmaAluno] = useState("0");
    const [optionsTurma, setOptionsTurma] = useState([]);
    const [search, setSearch] = useState("");

    function handleSearch() {
        if(String(search).length > 0 || Number(id_turma_aluno) > 0){
            if(busca === "id_turma_aluno"){
                const filter = allRows.filter(a => Number(a.id_turma_aluno) === Number(id_turma_aluno));
                setRows(filter);
            }else{
                const filter = allRows.filter(a => String(a[busca]).includes(String(search)));
                setRows(filter);
            }
        }else{
            setRows(allRows);
        }
        setOpen(false);
    }

    async function loadTurmas() {
        try {
            const getAllTurmas = await api.get(`/turmas`);
            if (getAllTurmas && getAllTurmas.status === 200 && getAllTurmas.data) {
                if (!getAllTurmas.data.error) {
                    const formatOptions = getAllTurmas.data.map(i => ({ value: i.id_turma, label: `${i.nome_turma} (${i.turno_turma})` }));
                    setOptionsTurma([{ value: "0", label: "---" }, ...formatOptions]);
                }
            }
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        loadTurmas();
    }, []);

    return (
        <DrawerApp
            open={open}
            setOpen={setOpen}
            title="FILTROS"
        >
            <ListItem>
                <SelectSimple
                    label="FILTRO"
                    value={busca}
                    setValue={setBusca}
                    options={[
                        { value: "id_turma_aluno", label: 'TURMA' },
                        { value: "responsavel", label: 'RESPONSAVEL' },
                        { value: "nome_mae", label: 'MÃE' },
                        { value: "nome_pai", label: 'PAI' },
                        { value: "bairro", label: 'BAIRRO' },
                    ]}
                />
            </ListItem>

            {busca === "id_turma_aluno" && (
                <ListItem>
                    <SelectSimple
                        label="TURMA"
                        value={id_turma_aluno}
                        setValue={setIdTurmaAluno}
                        options={optionsTurma}
                    />
                </ListItem>
            )}
            {["responsavel", "nome_mae", "nome_pai", "bairro"].includes(busca) && (
                <ListItem>
                    <Input
                        label="BUSCA"
                        value={search}
                        setValue={setSearch}
                        onKeyUp={e => {
                            if (e.key === 'Enter') handleSearch();
                        }}
                    />
                </ListItem>
            )}

            <ListItem>
                <Button
                    variant="contained"
                    fullWidth
                    onClick={() => handleSearch()}
                >
                    BUSCAR
                </Button>
            </ListItem>
        </DrawerApp>
    );
}