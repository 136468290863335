import { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import ListActions from "../../components/Lists/ListActions";
import Modal from "../../components/Modal/Modal";
import { AlertConfirm, AlertInfo, AlertQuestion } from '../../components/Alerts/Alert';
import { api } from "../../services/api";

export default function Disciplinas({ open, setOpen }) {
    const [infoLogin] = useState(JSON.parse(sessionStorage.getItem('infoLogin')));
    const [id_escola_disciplina] = useState(infoLogin.id_escola_usuario || 0);
    const [disciplinas, setDisciplinas] = useState([]);

    function deleteDisciplina(disciplina) {
        AlertConfirm('', 'REMOVER MATERIA?').then(async res => {
            if(res.isConfirmed){
                const { id_disciplina = 0 } = disciplina;
                const response = await api.post(`deletedisciplina`, { id_disciplina });
                if(response && response.status === 200 && response.data){
                    if(response.data.success) AlertInfo('success', 'DISCIPLINA REMOVIDA');
                    else AlertInfo('error', response.data.message);
                }
                loadDiscliplinas();
            }
        });
    }

    async function loadDiscliplinas() {
        try {
            const response = await api.post(`/disciplinas`, { id_escola_disciplina });
            if (response && response.status === 200 && response.data) {
                if (response.data.error) AlertInfo('error', response.data.message);
                else setDisciplinas(response.data);
            } else {
                AlertInfo('error', 'FALHA NA OPERAÇÃO');
            }
        } catch (error) {
            console.log(error);
            AlertInfo('error', 'FALHA NA CONEXÃO');
        }
    }

    function addDisciplina() {
        setOpen(false);
        AlertQuestion('DISCIPLINA', '', '', true, 'Disciplina Invalida').then(async res => {
            if(res.isConfirmed){
                setOpen(true);
                const data = { id_escola_disciplina, disciplina: String(res.value).toUpperCase() };
                const response = await api.post(`createdisciplina`, data);
                if(response && response.status === 200 && response.data){
                    if(response.data.error) AlertInfo('error', response.data.message);
                    else loadDiscliplinas();
                }
            }
        });
    }

    useEffect(() => {
        loadDiscliplinas();
        // eslint-disable-next-line
    }, []);

    return (
        <Modal
            open={open}
            setOpen={setOpen}
            title="DISCIPLINAS"
        >
            <div style={{width: '100%', display: 'flex', justifyContent: 'center'}}>
                <Button
                    variant="contained"
                    color='success'
                    onClick={() => addDisciplina()}
                >
                    ADICIONAR
                </Button>
            </div>
            <ListActions
                array={disciplinas}
                keyItem="disciplina"
                emptyText='NENHUMA DISCIPLINA CADASTRADA'
                deleteFunc={deleteDisciplina}
            />
        </Modal>
    );
}