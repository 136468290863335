import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { FaTachometerAlt, FaChevronCircleDown, FaChevronCircleUp, FaList } from 'react-icons/fa';
import { FaFolderOpen, FaUsers, FaUserGraduate, FaUserFriends, FaChalkboardTeacher } from 'react-icons/fa';
import { FaDollarSign, FaHandHoldingUsd, FaFileInvoiceDollar, FaMoneyBillAlt } from 'react-icons/fa';
import { FaCogs, FaTools, FaTasks, FaUserLock, FaBuilding, FaInfo, FaSchool } from 'react-icons/fa';
import Collapse from '@mui/material/Collapse';
import Typography from '@mui/material/Typography';
import isPermission from '../../services/permission';

import './style.css';

const SideBar = ({ menuActive }) => {
    const [subMenu, setSubMenu] = useState(sessionStorage.getItem('subMenu') || "");
    const navigate = useNavigate();

    const colapseMenu = (expand) => {
        console.log(expand, subMenu);
        if(subMenu === expand){
            setSubMenu("");
            sessionStorage.setItem('subMenu', "");
        }else{
            setSubMenu(expand);
            sessionStorage.setItem('subMenu', expand);
        }
    }

    const MenuItem = ({ menu = null, sub = '', IconMenu, label = '', itemSubMenu = null, ...rest }) => {
        const active = (menuActive === menu)?'menu-active':'';
    
        return(
            <div className={`menu-item-sidebar ${sub} ${active}`} { ...rest }>
                <div className="link-menu-sidebar">
                    <IconMenu/>
                    <Typography component="h6">{ label }</Typography>
                </div>
                {itemSubMenu && (
                    <div>
                        {(subMenu === itemSubMenu) ? <FaChevronCircleUp /> : <FaChevronCircleDown />}
                    </div>
                )}
            </div>
        );
    }

    const openPageMenu = (page = '', codPermite = '0') => {
        navigate(`/${page}`);
    }

    return(
        <div className="side-area">
            <div className="header-menu" />
            <MenuItem
                menu="home"
                IconMenu={FaTachometerAlt}
                label="INICIO"
                onClick={() => { openPageMenu('', '0'); }}
            />

            <MenuItem
                IconMenu={FaFolderOpen}
                label="CADASTROS"
                onClick={() => colapseMenu('cadastros')}
                itemSubMenu="cadastros"
            />
            <Collapse in={(subMenu ===  'cadastros') ? true : false}>
                {isPermission(1) && (
                    <Link to="/usuarios">
                        <MenuItem
                            menu="usuarios"
                            IconMenu={FaUserLock}
                            label="USUARIOS"
                            sub="menu-submenu"
                        />
                    </Link>
                )}

                {isPermission(5) && (
                    <Link to="/professores">
                        <MenuItem
                            menu="professores"
                            IconMenu={FaChalkboardTeacher}
                            label="PROFESSORES"
                            sub="menu-submenu"
                        />
                    </Link>
                )}
                
                {isPermission(11) && (
                    <Link to="/funcionarios">
                        <MenuItem
                            menu="funcionarios"
                            IconMenu={FaUserFriends}
                            label="FUNCIONARIOS"
                            sub="menu-submenu"
                        />
                    </Link>
                )}

                {isPermission(20) && (
                    <Link to="/alunos">
                        <MenuItem
                            menu="alunos"
                            IconMenu={FaUserGraduate}
                            label="ALUNOS"
                            sub="menu-submenu"
                        />
                    </Link>
                )}
            </Collapse>

            <MenuItem
                IconMenu={FaSchool}
                label="ESCOLA"
                onClick={() => colapseMenu('escola')}
                itemSubMenu="escola"
            />
            <Collapse in={(subMenu ===  'escola') ? true : false}>
                <Link to="/definir">
                    <MenuItem
                        menu="definir"
                        IconMenu={FaList}
                        label="DEFINIÇÕES"
                        sub="menu-submenu"
                    />
                </Link>
                {isPermission(40) && (
                    <Link to="/turmas">
                        <MenuItem
                            menu="turmas"
                            IconMenu={FaUsers}
                            label="TURMAS"
                            sub="menu-submenu"
                        />
                    </Link>
                )}
            </Collapse>

            <MenuItem
                IconMenu={FaDollarSign}
                label="FINANCEIRO"
                onClick={() => colapseMenu('financeiro')}
                itemSubMenu="financeiro"
            />
            <Collapse in={(subMenu ===  'financeiro') ? true : false}>
                {isPermission(60) && (
                    <Link to="/mensalidades">
                        <MenuItem
                            menu="mensalidades"
                            IconMenu={FaHandHoldingUsd}
                            label="MENSALIDADES"
                            sub="menu-submenu"
                        />
                    </Link>
                )}

                {/* <MenuItem
                    menu="pagar"
                    IconMenu={FaFileInvoiceDollar}
                    label="CONTAS A PAGAR"
                    onClick={() => openPageMenu('pagar', '40')}
                    sub="menu-submenu"
                />

                <MenuItem
                    menu="caixa"
                    IconMenu={FaMoneyBillAlt}
                    label="CAIXA"
                    onClick={() => openPageMenu('caixa', '50')}
                    sub="menu-submenu"
                /> */}
            </Collapse>
            
            {/* <MenuItem
                IconMenu={FaCogs}
                label="DEFINIÇÕES"
                onClick={() => colapseMenu('config')}
                itemSubMenu="config"
            />
            <Collapse in={(subMenu ===  'config') ? true : false}>
                <MenuItem
                    menu="config-geral"
                    IconMenu={FaTools}
                    label="CONFIGURAÇÕES"
                    onClick={() => openPageMenu('config-geral', '65')}
                    sub="menu-submenu"
                />

                <MenuItem
                    menu="empresa"
                    IconMenu={FaBuilding}
                    label="EMPRESA"
                    onClick={() => openPageMenu('empresa', '80')}
                    sub="menu-submenu"
                />
                <MenuItem
                    menu="info"
                    IconMenu={FaInfo}
                    label="INFORMAÇÕES"
                    onClick={() => openPageMenu('info', '0')}
                    sub="menu-submenu"
                />
            </Collapse> */}
            
        </div>
    );
}

export default SideBar;