import { FaSchool } from 'react-icons/fa';
import Template from "../../template/Template";

export default function Home() {
    return(
        <Template menuActive="home">
            <div style={{display:'flex', flexDirection:'column', width:'100%', height:'80vh', justifyContent:'center', alignItems: 'center'}}>
                <FaSchool size={132}/>
                <h1>BEM VINDO AO LINK COLEGIO</h1>
            </div>
        </Template>
    );
}