import { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import ListActions from "../../components/Lists/ListActions";
import Modal from "../../components/Modal/Modal";
import { api } from "../../services/api";
import { AlertConfirm, AlertInfo, AlertQuestion } from "../../components/Alerts/Alert";

export default function Cargos({ open, setOpen }) {
    const [infoLogin] = useState(JSON.parse(sessionStorage.getItem('infoLogin')));
    const [id_escola_cargo] = useState(infoLogin.id_escola_usuario || 0);
    const [cargos, setCargos] = useState([]);

    function deleteCargo(cargo) {
        AlertConfirm('', 'REMOVER CARGO?').then(async res => {
            if(res.isConfirmed){
                const { id_cargo = 0 } = cargo;
                const response = await api.post(`deletecargo`, { id_cargo });
                if(response && response.status === 200 && response.data){
                    if(response.data.success) AlertInfo('success', 'CARGO REMOVIDO');
                    else AlertInfo('error', response.data.message);
                }
                loadCargos();
            }
        });
    }

    async function loadCargos() {
        try {
            const response = await api.post(`/cargos`, { id_escola_cargo });
            if (response && response.status === 200 && response.data) {
                if (response.data.error) AlertInfo('error', response.data.message);
                else setCargos(response.data);
            } else {
                AlertInfo('error', 'FALHA NA OPERAÇÃO');
            }
        } catch (error) {
            console.log(error);
            AlertInfo('error', 'FALHA NA CONEXÃO');
        }
    }

    function addCargo() {
        setOpen(false);
        AlertQuestion('CARGO', '', '', true, 'Cargo Invalida').then(async res => {
            if(res.isConfirmed){
                setOpen(true);
                const data = { id_escola_cargo, cargo: String(res.value).toUpperCase() };
                const response = await api.post(`createcargo`, data);
                if(response && response.status === 200 && response.data){
                    if(response.data.error) AlertInfo('error', response.data.message);
                    else loadCargos();
                }
            }
        });
    }

    useEffect(() => {
        loadCargos();
        // eslint-disable-next-line
    }, []);

    return (
        <Modal
            open={open}
            setOpen={setOpen}
            title="GARGOS"
        >
            <div style={{width: '100%', display: 'flex', justifyContent: 'center'}}>
                <Button
                    variant="contained"
                    color='success'
                    onClick={() => addCargo()}
                >
                    ADICIONAR
                </Button>
            </div>
            <ListActions
                array={cargos}
                keyItem="cargo"
                emptyText='NENHUM CARGO CADASTRADO'
                deleteFunc={deleteCargo}
            />
        </Modal>
    );
}