import { FaPlus } from 'react-icons/fa';

export default function DisplayHorTurma({ item = null }) {
    function displayNameProf(name) {
        const arrName = String(name).split(' ');
        let displayName = arrName[0];
        if(arrName[1]) displayName += `${displayName} ${arrName[1]}`;
        if(arrName[1] && arrName[1].length <= 3 && arrName[2]) displayName += `${displayName} ${arrName[2]}`;
        return displayName;
    }

    return (
        <div style={{ height: item ? 'auto' : 50, textAlign: 'center' }}>
            {!item && (
                <div style={{ display: 'flex', justifyContent: ' center', alignItems: 'center', flexDirection: 'column' }}>
                    <FaPlus />
                    <span>ADICIONAR</span>
                </div>
            )}

            {item && Boolean(item.is_interval) && (
                <div>
                    <span>INTERVALO</span>
                </div>
            )}

            {item && !Boolean(item.is_interval) && (
                <>
                    <div>
                        <span>{item ? item.disciplina : ""}</span>
                    </div>
                    <div>
                        <span>{item ? displayNameProf(item.professor) : ""}</span>
                    </div>
                    <div>
                        <span>{item ? item.sala : ""}</span>
                    </div>
                </>
            )}

            <div>
                <span>{item ? `${item.hora_inicio} - ${item.hora_fim}` : ""}</span>
            </div>
        </div>
    );
}