import { useState } from 'react';
import Grid from '@mui/material/Grid';
import Template from "../../template/Template";
import CardButton from '../../components/CardButton/CardButton';
import Disciplinas from './Disciplinas';
import Salas from './Salas';
import Cargos from './Cargos';
import Planos from './Planos';

export default function Definir() {
    const [openModalDisciplinas, setOpenModalDisciplinas] = useState(false);
    const [openModalSalas, setOpenModalSalas] = useState(false);
    const [openModalCargos, setOpenModalCargos] = useState(false);
    const [openModalPlanos, setOpenModalPlanos] = useState(false);
    
    return(
        <Template menuActive="definir">
            <Disciplinas
                open={openModalDisciplinas}
                setOpen={setOpenModalDisciplinas}
            />
            
            <Salas
                open={openModalSalas}
                setOpen={setOpenModalSalas}
            />
            
            <Cargos
                open={openModalCargos}
                setOpen={setOpenModalCargos}
            />
            
            <Planos
                open={openModalPlanos}
                setOpen={setOpenModalPlanos}
            />

            <Grid container spacing={3}>
                <Grid item xs={12} sm={2}>
                    <CardButton label="DISCIPLINAS" icon='book' onClick={() => setOpenModalDisciplinas(true)} />
                </Grid>
                <Grid item xs={12} sm={2}>
                    <CardButton label="SALAS" icon='sala' onClick={() => setOpenModalSalas(true)} />
                </Grid>
                <Grid item xs={12} sm={2}>
                    <CardButton label="CARGOS" icon='work' onClick={() => setOpenModalCargos(true)} />
                </Grid>
                <Grid item xs={12} sm={2}>
                    <CardButton label="PLANOS" icon='fileDolar' onClick={() => setOpenModalPlanos(true)} />
                </Grid>
            </Grid>
        </Template>
    );
}