import { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Template from "../../template/Template";
import isPermission from '../../services/permission';
import ModalCadastroTurma from './ModalCadastroTurma';
import CardButton from '../../components/CardButton/CardButton';
import SimpleBackdrop from '../../components/Loading/SimpleBackdrop';
import { AlertInfo } from '../../components/Alerts/Alert';
import { api } from '../../services/api';

export default function Turmas() {
    const [openModalCad, setOpenModalCad] = useState(false);
    const [openLoad, setOpenLoad] = useState(false);
    const [infoModal, setInfoModal] = useState(null);
    const [rows, setRows] = useState([]);

    async function loadData() {
        setOpenLoad(true);
        try {
            const response = await api.get(`/turmas`);
            if(response && response.status === 200 && response.data){
                if(response.data.error) AlertInfo('error', response.data.message);
                else setRows(response.data);
            }
        } catch (error) {
            console.log(error);
            AlertInfo('error', 'FALHA NA CONEXÃO');
        }
        setOpenLoad(false);
    }

    async function openModalInfo(info) {
        setOpenLoad(true);
        try {
            const response = await api.get(`/turma/${info.id_turma}`);
            if(response && response.status === 200 && response.data){
                if(response.data.error){
                    AlertInfo('error', 'FALHA NA CONSULTA')
                }else{
                    setInfoModal(response.data);
                    setOpenModalCad(response.data);
                }
            }
        } catch (error) {
            console.log(error);
            AlertInfo('error', 'FALHA NA CONEXÃO');
        }
        setOpenLoad(false);
    }

    useEffect(() => {
        loadData();
    }, []);

    return (
        <Template menuActive="turmas">
            <SimpleBackdrop open={openLoad} />
            <ModalCadastroTurma
                open={openModalCad}
                setOpen={setOpenModalCad}
                infoModal={infoModal}
                setInfoModal={setInfoModal}
                load={loadData}
            />
            <Grid container spacing={2}>
                <Grid item xs={12} sm={2}>
                    {isPermission(41) && (
                        <Button
                            variant="contained"
                            fullWidth
                            onClick={() => setOpenModalCad(true)}
                        >
                            NOVA TURMA
                        </Button>
                    )}
                </Grid>
                <Grid item xs={false} sm={10}></Grid>
                
                {rows.map(tur => (
                    <Grid item xs={12} sm={2} key={tur.id_turma}>
                        <CardButton label={tur.nome_turma} icon='users' onClick={() => openModalInfo(tur)} />
                    </Grid>
                ))}
            </Grid>
        </Template>
    );
}