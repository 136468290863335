import { useState, useEffect } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { FaTrash } from 'react-icons/fa';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Template from "../../template/Template";
import SimpleBackdrop from '../../components/Loading/SimpleBackdrop';
import Input from '../../components/Inputs/Input';
import SelectSimple from '../../components/Selects/SelectSimple';
import { AlertConfirm, AlertInfo } from '../../components/Alerts/Alert';
import { api } from '../../services/api';
import isPermission from '../../services/permission';
import { arrayUf } from '../../data/uf';
import SelectCity from '../../components/Selects/SelectCity';
import { dateEua, isDate } from '../../services/date';

export default function CadastroAluno() {
    const navigate = useNavigate();
    const [searchParms] = useSearchParams();
    const [infoLogin] = useState(JSON.parse(sessionStorage.getItem('infoLogin')));
    const [id_escola_aluno] = useState(infoLogin.id_escola_usuario || 0);
    const [openLoad, setOpenLoad] = useState(false);
    
    const [id_aluno, setIdAluno] = useState(null);
    const [matricula_aluno, setMatriculaAluno] = useState("");
    const [nome_aluno, setNomeAluno] = useState("");
    const [cpf, setCpf] = useState("");
    const [endereco, setEndereco] = useState("");
    const [numero, setNumero] = useState("");
    const [bairro, setBairro] = useState("");
    const [complemento, setComplemento] = useState("");
    const [uf, setUf] = useState(infoLogin.uf || "PA");
    const [cidade, setCidade] = useState(infoLogin.cidade || "");
    const [cep, setCep] = useState(infoLogin.cep || "");
    const [telefone, setTelefone] = useState("");
    const [email, setEmail] = useState("");
    const [data_nascimento, setDataNascimento] = useState("");
    const [nome_mae, setNomeMae] = useState("");
    const [contato_mae, setContatoMae] = useState("");
    const [nome_pai, setNomePai] = useState("");
    const [contato_pai, setContatoPai] = useState("");
    const [responsavel, setResponsavel] = useState("");
    const [cpf_responsavel, setCpfResponsavel] = useState("");
    const [telefone_responsavel, setTelefoneResponsavel] = useState("");
    const [data_matricula, setDataMatricula] = useState("");
    const [adicional, setAdicional] = useState("");
    const [id_turma_aluno, setIdTurmaAluno] = useState("0");
    const [optionsTurma, setOptionsTurma] = useState([]);

    function setDataUser(data) {
        setIdAluno(data.id_aluno);
        setMatriculaAluno(data.matricula_aluno);
        setNomeAluno(data.nome_aluno);
        setCpf(data.cpf);
        setEndereco(data.endereco);
        setNumero(data.numero);
        setBairro(data.bairro);
        setComplemento(data.complemento);
        setUf(data.uf);
        setCidade(data.cidade);
        setCep(data.cep);
        setTelefone(data.telefone);
        setEmail(data.email);
        if(data.data_nascimento) setDataNascimento(dateEua(data.data_nascimento));
        setAdicional(data.adicional);
        setNomeMae(data.nome_mae);
        setContatoMae(data.contato_mae);
        setNomePai(data.nome_pai);
        setContatoPai(data.contato_pai);
        setResponsavel(data.responsavel);
        setCpfResponsavel(data.cpf_responsavel);
        setTelefoneResponsavel(data.telefone_responsavel);
        if(data.data_matricula) setDataMatricula(dateEua(data.data_matricula));
        setIdTurmaAluno(data.id_turma_aluno);
    }

    async function loadData(cod = null) {
        setOpenLoad(true);
        const id = cod ? cod : searchParms.get('id');
        if(id){
            try {
                const data = {id_aluno: id};
                const response = await api.post(`/aluno`, data);
                if(response && response.status === 200 && response.data){
                    if(response.data.error) AlertInfo("error", response.data.message);
                    else setDataUser(response.data[0]);
                }
            } catch (error) {
                
            }
        }
        try {
            const getAllTurmas = await api.get(`/turmas`);
            if(getAllTurmas && getAllTurmas.status === 200 && getAllTurmas.data){
                if(!getAllTurmas.data.error){
                    const formatOptions = getAllTurmas.data.map(i => ({value: i.id_turma, label: `${i.nome_turma} (${i.turno_turma})`}));
                    setOptionsTurma([{value: "0", label: "---"}, ...formatOptions]);
                }
            }
        } catch (error) {
            console.log(error);
        }
        setOpenLoad(false);
    }

    async function handleSave() {
        setOpenLoad(true);
        try {
            if(isPermission(21) || isPermission(22)){
                const data = {
                    id_aluno, id_escola_aluno, nome_aluno, cpf, endereco, numero, bairro, complemento,
                    uf, cidade, cep, telefone, email, adicional, matricula_aluno, nome_mae, contato_mae,
                    nome_pai, contato_pai, responsavel, cpf_responsavel, telefone_responsavel,
                    data_nascimento: isDate(data_nascimento), id_turma_aluno,
                    data_matricula: isDate(data_matricula) ? data_matricula : dateEua(),
                };
                const channel = id_aluno ? `/updatealuno` : `/createaluno`;
                const response = await api.post(channel, data);
                let error = 'FALHA NA OPERAÇÃO';
                if(response && response.status === 200 && response.data){
                    if(response.data.error){
                        error = response.data.message;
                    }else{
                        setOpenLoad(false);
                        AlertInfo('success', 'Dados Gravados').then(() => {
                            navigate('/alunos');
                        });
                        return;
                    }
                }
                AlertInfo('error', error);
            }
        } catch (error) {
            console.log(error);
            AlertInfo("error", "FALHA NA CONEXÃO");
        }
        setOpenLoad(false);
    }

    function handleDelete() {
        AlertConfirm('', "EXCLUIR ALUNO(A)?").then(async res => {
            if(res.isConfirmed){
                setOpenLoad(true);
                const response = await api.post(`/deletealuno`, { id_aluno });
                if(response && response.status && response.data){
                    if(response.data.success){
                        AlertInfo("success", "ALUNO(A) REMOVIDO").then(() => {
                            navigate('/alunos');
                        });
                    }else if(response.data.error){
                        AlertInfo("error", response.data.message);
                    }
                }else{
                    AlertInfo("error", "Falha na operação");
                }
                setOpenLoad(false);
            }
        });
    }

    async function isExistData(key) {
        if(!id_aluno){
            let data = null;
            if(key === 'CPF' && cpf.length > 0) data = {cpf, aluno_ativo: 1};
            else if(key === 'MATRICULA' && matricula_aluno.length > 0) data = {matricula_aluno, aluno_ativo: 1};
            
            if(data){
                const response = await api.post(`/aluno`, data);
                if(response && response.status === 200 && response.data){
                    if(!response.data.error && response.data[0] && response.data[0].id_aluno){
                        AlertInfo('info', `${key} JÁ CADASTRADO COMO ALUNO`).then(() => {
                            loadData(response.data[0].id_aluno);
                        });
                    }
                }
            }
        }
    }

    useEffect(() => {
        loadData();
        // eslint-disable-next-line
    }, []);

    return(
        <Template menuActive="alunos">
            <br/>
            <Paper elevation={3} style={{padding: 8}}>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <h2 style={{textAlign:'center'}}>DADOS DO ALUNO</h2>
                    </Grid>

                    
                    <Grid item xs={12} sm={3}>
                        <Input
                            id="matricula_aluno"
                            label="MATRICULA"
                            value={matricula_aluno}
                            setValue={setNomeAluno}
                            next="nome_aluno"
                            onBlur={() => isExistData('MATRICULA')}
                        />
                    </Grid>
                    
                    <Grid item xs={12} sm={6}>
                        <Input
                            id="nome_aluno"
                            label="NOME COMPLETO ALUNO(A)"
                            value={nome_aluno}
                            setValue={setNomeAluno}
                            next="cpf"
                        />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <Input
                            id="cpf"
                            label="CPF"
                            value={cpf}
                            setValue={setCpf}
                            next="endereco"
                            mask="cpf"
                            onBlur={() => isExistData('CPF')}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Input
                            id="endereco"
                            label="ENDEREÇO"
                            value={endereco}
                            setValue={setEndereco}
                            next="numero"
                        />
                    </Grid>
                    <Grid item xs={12} sm={2}>
                        <Input
                            id="numero"
                            label="NUMERO"
                            value={numero}
                            setValue={setNumero}
                            next="bairro"
                        />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <Input
                            id="bairro"
                            label="BAIRRO"
                            value={bairro}
                            setValue={setBairro}
                            next="complemento"
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Input
                            id="complemento"
                            label="COMPLEMENTO"
                            value={complemento}
                            setValue={setComplemento}
                            next="telefone"
                        />
                    </Grid>
                    <Grid item xs={12} sm={2}>
                        <SelectSimple
                            label="UF"
                            value={uf}
                            setValue={setUf}
                            options={arrayUf}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <SelectCity
                            uf={uf}
                            cidade={cidade}
                            setCidade={setCidade}
                        />
                    </Grid>
                    <Grid item xs={12} sm={2}>
                        <Input
                            id="cep"
                            label="CEP"
                            value={cep}
                            setValue={setCep}
                            next="telefone"
                        />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <Input
                            id="telefone"
                            label="TELEFONE"
                            value={telefone}
                            setValue={setTelefone}
                            mask="telefone"
                            next="email"
                        />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <Input
                            id="email"
                            label="EMAIL"
                            value={email}
                            setValue={setEmail}
                            next="data_nascimento"
                        />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <Input
                            type="date"
                            id="data_nascimento"
                            label="DATA DE NASCIMENTO"
                            value={data_nascimento}
                            setValue={setDataNascimento}
                            upper={false}
                            next="nome_mae"
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </Grid>

                    <Grid item xs={12} sm={3}>
                        <Input
                            id="nome_mae"
                            label="NOME DA MÃE"
                            value={nome_mae}
                            setValue={setNomeMae}
                            next="contato_mae"
                        />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <Input
                            id="contato_mae"
                            label="TELEFONE DA MÃE"
                            value={contato_mae}
                            setValue={setContatoMae}
                            next="nome_pai"
                            mask="telefone"
                        />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <Input
                            id="nome_pai"
                            label="NOME DO PAI"
                            value={nome_pai}
                            setValue={setNomePai}
                            next="contato_pai"
                        />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <Input
                            id="contato_pai"
                            label="TELEFONE DO PAI"
                            value={contato_pai}
                            setValue={setContatoPai}
                            next="responsavel"
                            mask="telefone"
                        />
                    </Grid>

                    <Grid item xs={12} sm={3}>
                        <Input
                            id="responsavel"
                            label="NOME RESPONSAVEL"
                            value={responsavel}
                            setValue={setResponsavel}
                            next="cpf_responsavel"
                        />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <Input
                            id="cpf_responsavel"
                            label="CPF RESPONSAVEL"
                            value={cpf_responsavel}
                            setValue={setCpfResponsavel}
                            next="telefone_responsavel"
                            mask="cpf"
                        />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <Input
                            id="telefone_responsavel"
                            label="TELEFONE DO RESPONSAVEL"
                            value={telefone_responsavel}
                            setValue={setTelefoneResponsavel}
                            next="data_matricula"
                            mask="telefone"
                        />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <Input
                            type="date"
                            id="data_matricula"
                            label="DATA MATRICULA"
                            value={data_matricula}
                            setValue={setDataMatricula}
                            upper={false}
                            next="adicional"
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </Grid>
                    
                    <Grid item xs={12} sm={6}>
                        <Input
                            id="adicional"
                            label="ADICIONAL"
                            value={adicional}
                            setValue={setAdicional}
                            next="salario_base"
                        />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <SelectSimple
                            label="TURMA"
                            value={id_turma_aluno}
                            setValue={setIdTurmaAluno}
                            options={optionsTurma}
                        />
                    </Grid>
                    

                    {id_aluno && isPermission(23) && (
                        <>
                            <Grid item xs={false} sm={10}></Grid>
                            <Grid item xs={false} sm={2}>
                                <Button 
                                    variant="contained"
                                    color='error'
                                    onClick={() => handleDelete()}
                                    fullWidth
                                >
                                    <FaTrash /> &nbsp; DELETAR
                                </Button>
                            </Grid>
                        </>
                    )}


                    <Grid item xs={false} sm={4}></Grid>
                    <Grid item xs={false} sm={4}>
                        <Button 
                            variant="contained"
                            color='success'
                            onClick={() => handleSave()}
                            fullWidth
                        >
                            SALVAR ALUNO(A)
                        </Button>
                    </Grid>
                    <Grid item xs={false} sm={4}></Grid>
                    
                </Grid>
            </Paper>

            <SimpleBackdrop open={openLoad} />
        </Template>
    );
}