import { useState, useEffect } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { FaTrash } from 'react-icons/fa';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Template from "../../template/Template";
import SimpleBackdrop from '../../components/Loading/SimpleBackdrop';
import Input from '../../components/Inputs/Input';
import { AlertConfirm, AlertInfo } from '../../components/Alerts/Alert';
import { api } from '../../services/api';
import isPermission from '../../services/permission';

export default function CadastroUsuario() {
    const navigate = useNavigate();
    const [searchParms] = useSearchParams();
    const [infoLogin] = useState(JSON.parse(sessionStorage.getItem('infoLogin')));
    const [id_escola_usuario] = useState(infoLogin.id_escola_usuario || 0);
    const [openLoad, setOpenLoad] = useState(false);
    
    const [id_usuario, setIdUsuario] = useState(null);
    const [nome_usuario, setNomeUsuario] = useState("");
    const [cpf_usuario, setCpfUsuario] = useState("");
    const [email_usuario, setEmailUsuario] = useState("");
    const [telefone_usuario, setTelefoneUsuario] = useState("");
    const [senha_usuario, setSenhaUsuario] = useState("");

    const [permissoes, setPermissoes] = useState([
        { id: 1, label: 'USUARIOS', value: false },
        { id: 2, label: 'CADASTRAR USUARIO', value: false },
        { id: 3, label: 'EDITAR USUARIO', value: false },
        { id: 4, label: 'DELETAR USUARIO', value: false },
        { id: 5, label: 'PROFESSORES', value: false },
        { id: 6, label: 'CADASTRAR PROFESSORES', value: false },
        { id: 7, label: 'EDITAR PROFESSORES', value: false },
        { id: 8, label: 'DELETAR PROFESSORES', value: false },
        { id: 11, label: 'FUNCIONARIOS', value: false },
        { id: 12, label: 'CADASTRAR FUNCIONARIOS', value: false },
        { id: 13, label: 'EDITAR FUNCIONARIOS', value: false },
        { id: 14, label: 'DELETAR FUNCIONARIOS', value: false },
        { id: 20, label: 'ALUNOS', value: false },
        { id: 21, label: 'CADASTRAR ALUNOS', value: false },
        { id: 22, label: 'EDITAR ALUNOS', value: false },
        { id: 23, label: 'DELETAR ALUNOS', value: false },
        { id: 30, label: 'DISCIPLINAS', value: false },
        { id: 31, label: 'SALAS', value: false },
        { id: 32, label: 'CARGOS', value: false },
        { id: 33, label: 'PLANOS', value: false },
        { id: 40, label: 'TURMAS', value: false },
        { id: 41, label: 'CRIAR TURMAS', value: false },
        { id: 60, label: 'MENSALIDADES', value: false },
        { id: 61, label: 'EMITIR MENSALIDADES', value: false },
        { id: 62, label: 'RECEBER MENSALIDADES', value: false },
        { id: 63, label: 'CANCELAR MENSALIDADES', value: false },
    ]);
    const [divGrid] = useState(Math.ceil(permissoes.length / 4));

    function setDataUser(data) {
        setIdUsuario(data.id_usuario);
        setNomeUsuario(data.nome_usuario);
        setCpfUsuario(data.cpf_usuario);
        setEmailUsuario(data.email_usuario);
        setTelefoneUsuario(data.telefone_usuario);
        const permissoes_usuario = String(data.permissoes_usuario).split(',');
        const up = permissoes.map(i => {
            const isPermite = permissoes_usuario.filter(p => Number(p) === Number(i.id));
            if(isPermite.length === 1) return {...i, value: true}
            return i;
        });
        setPermissoes(up);
    }

    async function loadData() {
        setOpenLoad(true);
        const id = searchParms.get('id');
        if(id){
            try {
                const data = {id_usuario: id, id_escola_usuario: infoLogin.id_escola_usuario};
                const response = await api.post(`/usuario`, data);
                if(response && response.status === 200 && response.data){
                    if(response.data.error) AlertInfo("error", response.data.message);
                    else setDataUser(response.data[0]);
                }
            } catch (error) {
                
            }
        }
        setOpenLoad(false);
    }

    function handleCheckPermission(id) {
        const up = permissoes.map(i => {
            if(i.id === id) return {...i, value: !i.value}
            return i;
        });
        setPermissoes(up);
    }

    async function handleSave() {
        setOpenLoad(true);
        try {
            if(isPermission(2) || isPermission(3)){
                const data = {
                    id_usuario, id_escola_usuario, nome_usuario, 
                    cpf_usuario, email_usuario, telefone_usuario, senha_usuario,
                    permissoes_usuario: permissoes.filter(p => p.value).map(p => p.id).toString()
                };
                const channel = id_usuario ? `/updateusuario` : `/createusuario`;
                const response = await api.post(channel, data);
                let error = 'FALHA NA OPERAÇÃO';
                if(response && response.status === 200 && response.data){
                    if(response.data.error){
                        error = response.data.message;
                    }else{
                        setOpenLoad(false);
                        AlertInfo('success', 'Dados Gravados').then(() => {
                            navigate('/usuarios');
                        });
                        return;
                    }
                }
                AlertInfo('error', error);
            }
        } catch (error) {
            console.log(error);
            AlertInfo("error", "FALHA NA CONEXÃO");
        }
        setOpenLoad(false);
    }

    function handleDelete() {
        AlertConfirm('', "EXCLUIR USUARIO?").then(async res => {
            if(res.isConfirmed){
                setOpenLoad(true);
                const response = await api.post(`/deleteusuario`, { id_usuario });
                if(response && response.status && response.data){
                    if(response.data.success){
                        AlertInfo("success", "USUARIO REMOVIDO").then(() => {
                            navigate('/usuarios');
                        });
                    }else if(response.data.error){
                        AlertInfo("error", response.data.message);
                    }
                }else{
                    AlertInfo("error", "Falha na operação");
                }
                setOpenLoad(false);
            }
        });
    }

    useEffect(() => {
        loadData();
        // eslint-disable-next-line
    }, []);

    return(
        <Template menuActive="usuarios">
            <br/>
            <Paper elevation={3} sx={{padding: 3}}>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <h2 style={{textAlign:'center'}}>DADOS DO USUARIO</h2>
                    </Grid>

                    <Grid item xs={false} sm={3}></Grid>
                    <Grid item xs={12} sm={6} container spacing={1}>
                        <Grid item xs={12} sm={10}>
                            <Input
                                id="nome_usuario"
                                label="NOME USUARIO"
                                value={nome_usuario}
                                setValue={setNomeUsuario}
                                next="cpf_usuario"
                            />
                        </Grid>
                        <Grid item xs={12} sm={5}>
                            <Input
                                id="cpf_usuario"
                                label="CPF USUARIO"
                                value={cpf_usuario}
                                setValue={setCpfUsuario}
                                next="email_usuario"
                                mask="cpf"
                            />
                        </Grid>
                        <Grid item xs={12} sm={5}>
                            <Input
                                id="email_usuario"
                                label="EMAIL USUARIO"
                                value={email_usuario}
                                setValue={setEmailUsuario}
                                next="telefone_usuario"
                                />
                        </Grid>
                        <Grid item xs={12} sm={5}>
                            <Input
                                id="telefone_usuario"
                                label="TELEFONE USUARIO"
                                value={telefone_usuario}
                                setValue={setTelefoneUsuario}
                                mask="telefone"
                                next="senha_usuario"
                            />
                        </Grid>
                        <Grid item xs={12} sm={5}>
                            <Input
                                id="senha_usuario"
                                label="SENHA USUARIO"
                                value={senha_usuario}
                                setValue={setSenhaUsuario}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={false} sm={3}></Grid>

                    <Grid item xs={12}>
                        <hr/>
                    </Grid>

                    <Grid item xs={12} container spacing={1}>
                        {[1, 2, 3, 4].map((arr, index) => (
                            <Grid item xs={12} sm={3}>
                                <FormGroup>
                                    {permissoes.slice((divGrid * index), (divGrid * arr)).map(i => (
                                        <FormControlLabel
                                            key={`permissao${i.id}`}
                                            control={
                                                <Checkbox 
                                                    checked={i.value} 
                                                    onChange={() => handleCheckPermission(i.id)} 
                                                    style={{ padding: '2px' }}
                                                />
                                            } 
                                            label={i.label} 
                                        />
                                    ))}
                                </FormGroup>
                            </Grid>
                        ))}
                    </Grid>

                    {id_usuario && isPermission(4) && (
                        <>
                            <Grid item xs={false} sm={10}></Grid>
                            <Grid item xs={false} sm={2}>
                                <Button 
                                    variant="contained"
                                    color='error'
                                    onClick={() => handleDelete()}
                                    fullWidth
                                >
                                    <FaTrash /> &nbsp; DELETAR
                                </Button>
                            </Grid>
                        </>
                    )}


                    <Grid item xs={false} sm={4}></Grid>
                    <Grid item xs={false} sm={4}>
                        <Button 
                            variant="contained"
                            color='success'
                            onClick={() => handleSave()}
                            fullWidth
                        >
                            SALVAR USUARIO
                        </Button>
                    </Grid>
                    <Grid item xs={false} sm={4}></Grid>
                    
                </Grid>
            </Paper>

            <SimpleBackdrop open={openLoad} />
        </Template>
    );
}