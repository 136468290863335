import { useState } from 'react';
import { FaUserGraduate, FaFileInvoiceDollar } from 'react-icons/fa';
import { Box, Button, Divider, Grid, LinearProgress } from "@mui/material";
import Modal from "../../components/Modal/Modal";
import TableSimple from '../../components/Tables/TableSimple';
import Input from '../../components/Inputs/Input';
import InputDisplay from '../../components/Inputs/InputDisplay';
import SimpleBackdrop from '../../components/Loading/SimpleBackdrop';
import { AlertInfo } from '../../components/Alerts/Alert';
import { api, asaas, asaasUrl } from '../../services/api';
import { displayMoney } from '../../services/displayValue';
import { isDate } from '../../services/date';
import { removeMask } from '../../components/Inputs/mask';

export default function EmitirMensalidades({ open, setOpen, loadData }) {
    const [infoLogin] = useState(JSON.parse(sessionStorage.getItem(`infoLogin`)));
    const [openModalAluno, setOpenModalAluno] = useState(false);
    const [openModalPlano, setOpenModalPlano] = useState(false);
    const [infoAluno, setInfoAluno] = useState(null);
    const [infoPlano, setInfoPlano] = useState(null);
    const [nome_aluno, setNomeAluno] = useState("");
    const [responsavel, setResponsavel] = useState("");
    const [openLoad, setOpenLoad] = useState(false);
    const [openLoadTotal, setOpenLoadTotal] = useState(false);
    const [rows, setRows] = useState([]);
    const [planos, setPlanos] = useState([]);
    const [vencimento, setVencimento] = useState("");
    const [parcelas, setParcelas] = useState(1);
    const [adicional, setAdicional] = useState("");
    const columnsAluno = [
        {
            id: 'matricula_aluno',
            numeric: false,
            label: 'MATRICULA',
        },
        {
            id: 'nome_aluno',
            numeric: false,
            label: 'ALUNO(A)',
        },
        {
            id: 'responsavel',
            numeric: false,
            label: 'RESPONSAVEL',
        },
    ];
    const columnsPlanos = [
        {
            id: 'plano',
            numeric: false,
            label: 'PLANO',
        },
        {
            id: 'valor_plano',
            numeric: true,
            label: 'VALOR',
            format: displayMoney
        }
    ];

    async function gerarCobranca() {
        if (isDate(vencimento)) {
            if (Number(parcelas) > 0) {
                setOpenLoadTotal(true);
                try {
                    const dataCliente = {
                        "name": infoAluno.responsavel,
                        "email": infoAluno.email,
                        "phone": removeMask(infoAluno.telefone_responsavel),
                        "cpfCnpj": removeMask(infoAluno.cpf_responsavel)
                    };
                    const dataAsaas = {
                        dataCliente,
                        apiKey: infoLogin.chave_api_key,
                        quantParcelas: parcelas,
                        plano: `*\n ALUNO: ${infoAluno.nome_aluno}\nTURMA: ${infoAluno.nome_turma}\n${infoPlano.plano}\n${adicional}`,
                        dataParcela: vencimento,
                        preco: infoPlano.valor_plano
                    };
                    const response = await asaas.post(`/createCarnet`, dataAsaas);
                    console.log(response);
                    if (response && response.status === 200 && response.data) {
                        const { dataCarnet, dataParcelas } = response.data;
                        const allParcelas = dataParcelas.data;
                        const dataParcelasApi = allParcelas.map(par => ({
                            id_escola_parcela: infoAluno.id_escola_aluno,
                            id_aluno_parcela: infoAluno.id_aluno,
                            parcela: par.installmentNumber,
                            quantidade_parcelas: parcelas,
                            situacao: par.status,
                            id_transaction: par.id,
                            carnet_id_parcela: par.installment,
                            link_parcela: par.bankSlipUrl,
                            valor_parcela: par.value,
                            vencimento: par.dueDate
                        }));
                        const saveParcelas = await api.post(`/createParcelas`, dataParcelasApi);
                        if(saveParcelas && saveParcelas.status === 200 && saveParcelas.data){
                            const data = {
                                apiKey: infoLogin.chave_api_key,
                                carnet_id: dataCarnet.installment
                            };
                            const response = await asaas.post(`/getCarnet`, data);
                            if (response && response.status === 200){
                                window.open(`${asaasUrl}/openCarnet/${data.carnet_id}`);
                                loadData();
                                setInfoAluno(null);
                                setInfoPlano(null);
                                setAdicional("");
                                setVencimento("");
                                setParcelas(1);
                                setOpen(false);
                            }
                        }
                    }
                } catch (error) {
                    console.log(error);
                    AlertInfo('error', 'FALHA NA CONEXÃO');
                }
                setOpenLoadTotal(false);
            } else {
                AlertInfo("error", "INSIRA UMA QUANTIDADE DE PARCELAS VALIDA");
            }
        } else {
            AlertInfo('error', 'INSIRA UMA DATA DE VENCIMENTE VALIDA');
        }
    }

    function handleSelectAluno(aluno) {
        setInfoAluno(aluno);
        setOpenModalAluno(false);
    }

    function handleSelectPlano(plano) {
        setInfoPlano(plano);
        setOpenModalPlano(false);
    }

    async function loadAluno(column, search) {
        setOpenLoad(true);
        try {
            const data = { column, search };
            const response = await api.post(`/searchalunos`, data);
            if (response && response.status === 200 && response.data) {
                if (response.data.error) AlertInfo("error", response.data.message);
                else setRows(response.data);
            }
        } catch (error) {
            console.log(error);
            AlertInfo("error", 'NÃO POSSIVEL BUSCAR DADOS');
        }
        setOpenLoad(false);
    }

    async function loadPlanos() {
        setOpenLoad(true);
        setOpenModalPlano(true);
        try {
            const response = await api.post(`/planos`);
            console.log(response);
            if (response && response.status === 200 && response.data) {
                if (response.data.error) AlertInfo('error', response.data.message);
                else setPlanos(response.data);
            } else {
                AlertInfo('error', 'FALHA NA OPERAÇÃO');
            }
        } catch (error) {
            console.log(error);
            AlertInfo('error', 'FALHA NA CONEXÃO');
        }
        setOpenLoad(false);
    }

    return (
        <Modal
            open={open}
            setOpen={setOpen}
            title="EMITIR MENSALIDADE"
            maxWidth="md"
        >
            <SimpleBackdrop open={openLoadTotal} />
            <Modal
                open={openModalAluno}
                setOpen={setOpenModalAluno}
                title="SELECIONAR ALUNO"
                maxWidth='md'
            >
                <Grid container spacing={1}>
                    <Grid item xs={12} sm={4}>
                        <Input
                            label="NOME ALUNO"
                            value={nome_aluno}
                            setValue={setNomeAluno}
                            disabled={openLoad}
                            onKeyUp={e => {
                                if (e.key === 'Enter') loadAluno('nome_aluno', nome_aluno);
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        {openLoad && (
                            <Box sx={{ width: '100%' }}>
                                <br />
                                <LinearProgress />
                            </Box>
                        )}
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <Input
                            label="RESPONSAVEL"
                            value={responsavel}
                            setValue={setResponsavel}
                            disabled={openLoad}
                            onKeyUp={e => {
                                if (e.key === 'Enter') loadAluno('responsavel', responsavel);
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TableSimple
                            rows={rows}
                            columns={columnsAluno}
                            clickFunc={handleSelectAluno}
                        />
                    </Grid>
                </Grid>
            </Modal>

            <Modal
                open={openModalPlano}
                setOpen={setOpenModalPlano}
                title="SELECIONAR PLANO"
                maxWidth='sm'
            >
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        {openLoad && (
                            <Box sx={{ width: '100%' }}>
                                <br />
                                <LinearProgress />
                            </Box>
                        )}
                    </Grid>
                    <Grid item xs={12}>
                        <TableSimple
                            rows={planos}
                            columns={columnsPlanos}
                            clickFunc={handleSelectPlano}
                        />
                    </Grid>
                </Grid>
            </Modal>

            <Grid container spacing={1}>
                <Grid item xs={false} sm={10}></Grid>
                <Grid item xs={12} sm={2}>
                    <Button
                        fullWidth
                        variant="contained"
                        onClick={() => setOpenModalAluno(true)}
                    >
                        <FaUserGraduate /> &nbsp; ALUNO
                    </Button>
                </Grid>

                {infoAluno && infoAluno.id_aluno && (
                    <>
                        <Grid item xs={12} sm={6} container spacing={0}>
                            <Grid item xs={12}>
                                <InputDisplay
                                    label="NOME COMPLETO ALUNO(A)"
                                    value={infoAluno.nome_aluno}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <InputDisplay
                                    label="MATRICULA"
                                    value={infoAluno.matricula_aluno}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <InputDisplay
                                    label="TURMA"
                                    value={`${infoAluno.nome_turma} (${infoAluno.turno_turma})`}
                                />
                            </Grid>
                        </Grid>

                        <Grid item xs={12} sm={6} container spacing={0}>
                            <Grid item xs={12}>
                                <InputDisplay
                                    label="RESPONSAVEL"
                                    value={infoAluno.responsavel}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <InputDisplay
                                    label="CPF RESPONSAVEL"
                                    value={infoAluno.cpf_responsavel}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <InputDisplay
                                    label="TELEFONE RESPONSAVEL"
                                    value={infoAluno.telefone_responsavel}
                                />
                            </Grid>
                        </Grid>
                    </>
                )}

                <Grid item xs={12}>
                    <Divider />
                </Grid>
                <Grid item xs={false} sm={10}></Grid>
                <Grid item xs={12} sm={2}>
                    <Button
                        fullWidth
                        variant="contained"
                        onClick={() => loadPlanos()}
                    >
                        <FaFileInvoiceDollar /> &nbsp; PLANO
                    </Button>
                </Grid>
                {infoPlano && infoPlano.id_plano && (
                    <>
                        <Grid item xs={12} sm={4}>
                            <InputDisplay
                                label="PLANO"
                                value={infoPlano.plano}
                            />
                        </Grid>
                        <Grid item xs={12} sm={2}>
                            <InputDisplay
                                label="VALOR"
                                value={displayMoney(infoPlano.valor_plano)}
                            />
                        </Grid>
                    </>
                )}

                {infoAluno && infoPlano && (
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <Divider />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Input
                                type="date"
                                label="VENCIMENTO PRIMEIRA PARCELA"
                                value={vencimento}
                                setValue={setVencimento}
                                upper={false}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Input
                                type="number"
                                label="PARCELAS"
                                value={parcelas}
                                setValue={setParcelas}
                                upper={false}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Input
                                type="text"
                                label="OBSERVAÇÕES"
                                value={adicional}
                                setValue={setAdicional}
                            />
                        </Grid>
                        <Grid item xs={false} sm={4} />
                        <Grid item xs={false} sm={4}>
                            <Button
                                fullWidth
                                variant="contained"
                                onClick={() => gerarCobranca()}
                            >
                                GERAR COBRANÇA
                            </Button>
                        </Grid>
                        <Grid item xs={false} sm={4} />
                    </Grid>
                )}
            </Grid>
        </Modal>
    );
}