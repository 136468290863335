import { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Modal from "../../components/Modal/Modal";
import Input from '../../components/Inputs/Input';
import SelectSimple from '../../components/Selects/SelectSimple';
import TableSimpleCell from '../../components/Tables/TableSimpleCell';
import DisplayHorTurma from '../../components/Turma/DisplayHorTurma';
import { AlertInfo } from '../../components/Alerts/Alert';
import { api } from '../../services/api';
import ModalAlunosTurma from './ModalAlunosTurma';

export default function ModalCadastroTurma({ open, setOpen, infoModal, load, setInfoModal }) {
    const [nome_turma, setNomeTurma] = useState("");
    const [turno_turma, setTurnoTurma] = useState("MANHÃ");
    const [isSabado, setIsSabado] = useState(false);
    const [isDomingo, setIsDomingo] = useState(false);
    const [horario_turma, setHorarioTurma] = useState([{ dom: null, seg: null, ter: null, qua: null, qui: null, sex: null, sab: null }]);
    const [infoTurma, setInfoTurma] = useState(null);
    const [openModalAlunos, setOpenModalAlunos] = useState(false);

    const [openModalAddH, setOpenModalAddH] = useState(false);
    const [dia_semana, setDiaSemana] = useState("seg");
    const [is_interval, setIsInterval] = useState(false);
    const [disciplina, setDisciplina] = useState("");
    const [arrDisciplinas, setArrDisciplinas] = useState([]);
    const [professor, setProfesor] = useState("");
    const [arrProfessor, setArrProfessor] = useState([]);
    const [sala, setSala] = useState("");
    const [arrSalas, setArrSalas] = useState([]);
    const [hora_inicio, setHoraInicio] = useState("");
    const [hora_fim, setHoraFim] = useState("");
    const [indexHr, setIndexHr] = useState(null);
    const [isRemove, setIsRemove] = useState(false);
    const [columnsHorarios, setColumnsHorarios] = useState([
        {
            id: 'seg',
            numeric: false,
            label: 'SEGUNDA',
            format: () => "",
            component: DisplayHorTurma
        },
        {
            id: 'ter',
            numeric: false,
            label: 'TERÇA',
            format: () => "",
            component: DisplayHorTurma
        },
        {
            id: 'qua',
            numeric: false,
            label: 'QUARTA',
            format: () => "",
            component: DisplayHorTurma
        },
        {
            id: 'qui',
            numeric: false,
            label: 'QUINTA',
            format: () => "",
            component: DisplayHorTurma
        },
        {
            id: 'sex',
            numeric: false,
            label: 'SEXTA',
            format: () => "",
            component: DisplayHorTurma
        },
    ]);

    function reloadComponent() {
        setNomeTurma("");
        setTurnoTurma("MANHÃ");
        setHorarioTurma([{ dom: null, seg: null, ter: null, qua: null, qui: null, sex: null, sab: null }]);

        setOpenModalAddH(false);
        setDiaSemana("seg");
        setIsInterval(false);
        setDisciplina("");
        setProfesor("");
        setSala("");
        setHoraInicio("");
        setHoraFim("");
        setIndexHr(null);
    }

    function removeHr() {
        let allRow = horario_turma;
        allRow[indexHr][dia_semana] = null;
        setHorarioTurma(allRow);
        setOpenModalAddH(false);
    }

    function addHr() {
        let error = null
        if (hora_fim.length === 0) error = "HORA DE TERMINO INVALIDA";
        if (hora_inicio.length === 0) error = "HORA DE INICIO INVALIDA";
        if (!is_interval && sala.length === 0) error = "SELECIONE UMA SALA";
        if (!is_interval && professor.length === 0) error = "SELECIONE UM PROFESSOR";
        if (!is_interval && disciplina.length === 0) error = "SELECIONE UMA DISCIPLINA";
        if (error) {
            AlertInfo('error', error);
            return;
        }
        let allRow = horario_turma;
        allRow[indexHr][dia_semana] = { disciplina, professor, sala, hora_inicio, hora_fim, is_interval };
        if (allRow[horario_turma.length - 1][dia_semana] !== null) allRow.push({ dom: null, seg: null, ter: null, qua: null, qui: null, sex: null, sab: null })
        setHorarioTurma(allRow);
        setOpenModalAddH(false);
    }

    async function loadData() {
        try {
            const response = await api.get(`/turmas/getOptionsTurma`);
            if (response && response.status === 200 && response.data) {
                if (!response.data.error) {
                    const { allDisciplinas = [], allProfessores = [], allSalas = [] } = response.data;
                    const none = { value: "", label: "---" };
                    setArrDisciplinas([none, ...allDisciplinas.map(i => ({ value: i.disciplina, label: i.disciplina }))]);
                    setArrProfessor([none, ...allProfessores.map(i => ({ value: i.nome_professor, label: i.nome_professor }))]);
                    setArrSalas([none, ...allSalas.map(i => ({ value: i.sala, label: i.sala }))]);
                }
            }
        } catch (error) {
        }
    }

    function openAdd(info, id, index) {
        if (info) {
            setIsInterval(Boolean(info.is_interval));
            setDisciplina(info.disciplina);
            setProfesor(info.professor);
            setSala(info.sala);
            setHoraInicio(info.hora_inicio);
            setHoraFim(info.hora_fim);
            setIsRemove(true);
        } else {
            setIsRemove(false);
        }
        setDiaSemana(id);
        setIndexHr(index);
        setOpenModalAddH(true);
    }

    function handleIsSabado() {
        const sab = {
            id: 'sab',
            numeric: false,
            label: 'SABADO',
            format: () => "",
            component: DisplayHorTurma
        };
        const is = !isSabado;
        let columnsAll = columnsHorarios;
        if (is) columnsAll.push(sab);
        else columnsAll.pop();
        setIsSabado(is);
        setColumnsHorarios(columnsAll);
    }

    function handleIsDomingo() {
        const dom = {
            id: 'dom',
            numeric: false,
            label: 'DOMINGO',
            format: () => "",
            component: DisplayHorTurma
        };
        const is = !isDomingo;
        let columnsAll = columnsHorarios;
        if (is) columnsAll.unshift(dom);
        else columnsAll.shift();
        setIsDomingo(is);
        setColumnsHorarios(columnsAll);
    }

    async function handleSaveTurma() {
        if (nome_turma.length === 0) {
            AlertInfo('error', 'PREENCHA O NOME DA TURMA');
        } else {
            let dataHorarios = [];
            for (let index = 0; index < horario_turma.length; index++) {
                const element = horario_turma[index];
                if (element.dom) dataHorarios.push({ ...element.dom, dia_semana: 'dom', index_row: index });
                if (element.seg) dataHorarios.push({ ...element.seg, dia_semana: 'seg', index_row: index });
                if (element.ter) dataHorarios.push({ ...element.ter, dia_semana: 'ter', index_row: index });
                if (element.qua) dataHorarios.push({ ...element.qua, dia_semana: 'qua', index_row: index });
                if (element.qui) dataHorarios.push({ ...element.qui, dia_semana: 'qui', index_row: index });
                if (element.sex) dataHorarios.push({ ...element.sex, dia_semana: 'sex', index_row: index });
                if (element.sab) dataHorarios.push({ ...element.sab, dia_semana: 'sab', index_row: index });
            }
            const data = {
                dataTurma: { nome_turma, turno_turma },
                dataHorarios
            };
            try {
                const channel = infoModal ? `/updateturma/${infoModal.turma[0].id_turma}` : `/createturma`;
                const response = await api.post(channel, data);
                if (response && response.status === 200 && response.data) {
                    if (response.data.error) {
                        AlertInfo('error', response.data.message);
                    } else {
                        AlertInfo('success', 'DADOS GRAVADOS');
                        setOpen(false);
                        load();
                        reloadComponent();
                    }
                }
            } catch (error) {
                console.log(error);
                AlertInfo('error', 'FALHA NA CONEXÃO');
            }
        }
    }

    function setDataInfoModal() {
        if (infoModal) {
            setNomeTurma(infoModal.turma[0].nome_turma);
            setTurnoTurma(infoModal.turma[0].turno_turma);
            const hrs = infoModal.horarios;
            let hrsTurma = [{ dom: null, seg: null, ter: null, qua: null, qui: null, sex: null, sab: null }];
            for (let index = 0; index < hrs.length; index++) {
                const element = hrs[index];
                if (hrsTurma[element.index_row]) {
                    let { disciplina, professor, sala, hora_inicio, hora_fim, is_interval } = element;
                    hrsTurma[element.index_row][element.dia_semana] = { disciplina, professor, sala, hora_inicio, hora_fim, is_interval };
                } else {
                    let modelSemana = { dom: null, seg: null, ter: null, qua: null, qui: null, sex: null, sab: null };
                    modelSemana[element.dia_semana] = element;
                    hrsTurma.push(modelSemana);
                }

            }
            console.log(hrsTurma);
            setHorarioTurma(hrsTurma);
        }
    }

    function showAlunos() {
        setInfoTurma(infoModal.turma[0]);
        setOpenModalAlunos(true);
    }

    useEffect(() => {
        setDataInfoModal();
        // eslint-disable-next-line
    }, [infoModal]);

    useEffect(() => {
        if (!open && infoModal) {
            reloadComponent();
            setInfoModal(null);
        }
        // eslint-disable-next-line
    }, [open]);

    useEffect(() => {
        loadData();
        // eslint-disable-next-line
    }, []);


    return (
        <Modal
            open={open}
            setOpen={setOpen}
            title="CADASTRO TURMA"
            maxWidth="md"
        >
            <ModalAlunosTurma
                open={openModalAlunos}
                setOpen={setOpenModalAlunos}
                infoTurma={infoTurma}
            />
            <Modal
                open={openModalAddH}
                setOpen={setOpenModalAddH}
                title="DEFINIR HORARIO"
            >
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <FormControlLabel
                            label="INTERVALO"
                            control={<Checkbox checked={is_interval} onChange={() => setIsInterval(!is_interval)} />}
                        />
                    </Grid>
                    {!is_interval && (
                        <>
                            <Grid item xs={12}>
                                <SelectSimple
                                    label="DISCIPLINA"
                                    value={disciplina}
                                    setValue={setDisciplina}
                                    options={arrDisciplinas}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <SelectSimple
                                    label="PROFESSOR(A)"
                                    value={professor}
                                    setValue={setProfesor}
                                    options={arrProfessor}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <SelectSimple
                                    label="SALA"
                                    value={sala}
                                    setValue={setSala}
                                    options={arrSalas}
                                />
                            </Grid>
                        </>
                    )}
                    <Grid item xs={12}>
                        <Input
                            type="time"
                            label="HORA INICIO"
                            value={hora_inicio}
                            setValue={setHoraInicio}
                            upper={false}
                            next="hora_fim"
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Input
                            id="hora_fim"
                            type="time"
                            label="HORA TERMINO"
                            value={hora_fim}
                            setValue={setHoraFim}
                            upper={false}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Button
                            variant="contained"
                            fullWidth
                            size='small'
                            onClick={() => addHr()}
                        >
                            ADICIONAR
                        </Button>
                    </Grid>
                    {isRemove && (
                        <Grid item xs={12}>
                            <Button
                                variant="contained"
                                fullWidth
                                size='small'
                                color='error'
                                onClick={() => removeHr()}
                            >
                                REMOVER
                            </Button>
                        </Grid>
                    )}
                </Grid>
            </Modal>

            <Grid container spacing={1}>
                <Grid item xs={false} sm={9}></Grid>
                <Grid item xs={12} sm={3}>
                    <Button
                        variant="contained"
                        fullWidth
                        size='small'
                        onClick={() => showAlunos()}
                    >
                        ALUNOS
                    </Button>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <Input
                        label="NOME TURMA"
                        value={nome_turma}
                        setValue={setNomeTurma}
                    />
                </Grid>
                <Grid item xs={false} sm={4}></Grid>
                <Grid item xs={12} sm={4}>
                    <SelectSimple
                        label="TURNO"
                        value={turno_turma}
                        setValue={setTurnoTurma}
                        options={[
                            { value: 'MANHÃ', label: 'MANHÃ' },
                            { value: 'TARDE', label: 'TARDE' },
                            { value: 'NOITE', label: 'NOITE' },
                        ]}
                    />
                </Grid>
                <Grid item xs={8}>
                    <h3>HORARIOS TURMA</h3>
                </Grid>
                <Grid item xs={12} sm={2}>
                    <FormControlLabel
                        label="DOMIGO"
                        control={<Checkbox checked={isDomingo} onChange={handleIsDomingo} />}
                    />
                </Grid>
                <Grid item xs={12} sm={2}>
                    <FormControlLabel
                        label="SABADO"
                        control={<Checkbox checked={isSabado} onChange={handleIsSabado} />}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TableSimpleCell
                        columns={columnsHorarios}
                        rows={horario_turma}
                        clickFunc={openAdd}
                    />
                </Grid>
                <Grid item xs={false} sm={4}></Grid>
                <Grid item xs={12} sm={4}>
                    <Button
                        variant="contained"
                        fullWidth
                        size='small'
                        onClick={() => handleSaveTurma()}
                    >
                        SALVAR
                    </Button>
                </Grid>
                <Grid item xs={false} sm={4}></Grid>
            </Grid>
        </Modal>
    );
}