import axios from "axios";

// export const apiUrl = 'http://localhost:4003';
export const apiUrl = 'https://apilinkcolegio.renovetecnologia.com';
export const asaasUrl = `https://connectasaas.renovetecnologia.com`;
// export const asaasUrl = `http://localhost:4004`;

const infoLogin = JSON.parse(sessionStorage.getItem("infoLogin"));

export const api = axios.create({
    baseURL: apiUrl,
    headers: {
        token: (infoLogin && infoLogin.token) ? infoLogin.token : ''
    }
});

export const addTokenApi = (token) => {
    api.interceptors.request.use(config => {
        config.headers['token'] = token;
        return config;
    });
    return true;
}

export const asaas = axios.create({
    baseURL: asaasUrl
});