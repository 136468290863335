import { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import ListActions from "../../components/Lists/ListActions";
import Modal from "../../components/Modal/Modal";
import { api } from "../../services/api";
import { AlertConfirm, AlertInfo, AlertQuestion } from "../../components/Alerts/Alert";

export default function Salas({ open, setOpen }) {
    const [infoLogin] = useState(JSON.parse(sessionStorage.getItem('infoLogin')));
    const [id_escola_sala] = useState(infoLogin.id_escola_usuario || 0);
    const [salas, setSalas] = useState([]);

    function deleteSala(sala) {
        AlertConfirm('', 'REMOVER SALA?').then(async res => {
            if(res.isConfirmed){
                const { id_sala = 0 } = sala;
                const response = await api.post(`deletesala`, { id_sala });
                if(response && response.status === 200 && response.data){
                    if(response.data.success) AlertInfo('success', 'SALA REMOVIDA');
                    else AlertInfo('error', response.data.message);
                }
                loadSalas();
            }
        });
    }

    async function loadSalas() {
        try {
            const response = await api.post(`/salas`, { id_escola_sala });
            if (response && response.status === 200 && response.data) {
                if (response.data.error) AlertInfo('error', response.data.message);
                else setSalas(response.data);
            } else {
                AlertInfo('error', 'FALHA NA OPERAÇÃO');
            }
        } catch (error) {
            console.log(error);
            AlertInfo('error', 'FALHA NA CONEXÃO');
        }
    }

    function addSala() {
        setOpen(false);
        AlertQuestion('SALA', '', '', true, 'Sala Invalida').then(async res => {
            if(res.isConfirmed){
                setOpen(true);
                const data = { id_escola_sala, sala: String(res.value).toUpperCase() };
                const response = await api.post(`createsala`, data);
                if(response && response.status === 200 && response.data){
                    if(response.data.error) AlertInfo('error', response.data.message);
                    else loadSalas();
                }
            }
        });
    }

    useEffect(() => {
        loadSalas();
        // eslint-disable-next-line
    }, []);

    return (
        <Modal
            open={open}
            setOpen={setOpen}
            title="SALAS"
        >
            <div style={{width: '100%', display: 'flex', justifyContent: 'center'}}>
                <Button
                    variant="contained"
                    color='success'
                    onClick={() => addSala()}
                >
                    ADICIONAR
                </Button>
            </div>
            <ListActions
                array={salas}
                keyItem="sala"
                emptyText='NENHUMA SALA CADASTRADA'
                deleteFunc={deleteSala}
            />
        </Modal>
    );
}