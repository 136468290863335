import { useEffect, useState } from "react";
import Modal from "../../components/Modal/Modal";
import TableSimple from "../../components/Tables/TableSimple";
import SimpleBackdrop from "../../components/Loading/SimpleBackdrop";
import { AlertInfo } from "../../components/Alerts/Alert";
import { api } from "../../services/api";

export default function ModalBuscaAluno({open, setOpen, busca, loadInfoParcela, outerClose = null}) {
    const [infoLogin] = useState(JSON.parse(sessionStorage.getItem('infoLogin')));
    const [openLoad, setOpenLoad] = useState(false);
    const [rows, setRows] = useState([]);
    const columns = [
        {
            id: 'matricula_aluno',
            numeric: false,
            label: 'MATRICULA',
        },
        {
            id: 'nome_aluno',
            numeric: false,
            label: 'ALUNO(A)',
        },
        {
            id: 'responsavel',
            numeric: false,
            label: 'RESPONSAVEL',
        },
        {
            id: 'nome_turma',
            numeric: false,
            label: 'TURMA',
        },
    ];

    function handleSelectName(aluno) {
        const search = { id_aluno_parcela: aluno.id_aluno };
        loadInfoParcela(search);
        setOpen(false);
        if(outerClose) outerClose(false);
    }

    async function loadData() {
        if(open && String(busca).length > 0){
            setOpenLoad(true);
            try {
                const data = { column: 'nome_aluno', search: busca, id_escola_aluno: infoLogin.id_escola_usuario };
                const response = await api.post(`/searchalunos`, data);
                if(response && response.status === 200 && response.data){
                    if(response.data.error) AlertInfo('error', response.data.message);
                    else setRows(response.data);
                }
            } catch (error) {
                console.log(error);
                AlertInfo('error', 'FALHA NA OPERAÇÃO');
            }
            setOpenLoad(false);
        }
    }

    useEffect(() => {
        loadData();
        // eslint-disable-next-line
    }, [open]);

    return (
        <Modal
            open={open}
            setOpen={setOpen}
            title={`PESQUISA POR: ${busca}`}
            zIndex={9999}
            maxWidth="md"
        >
            <SimpleBackdrop open={openLoad} />
            <TableSimple
                rows={rows}
                columns={columns}
                clickFunc={handleSelectName}
            />
        </Modal>
    );
}