import { useState, useEffect } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { FaTrash } from 'react-icons/fa';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Template from "../../template/Template";
import SimpleBackdrop from '../../components/Loading/SimpleBackdrop';
import Input from '../../components/Inputs/Input';
import SelectSimple from '../../components/Selects/SelectSimple';
import { AlertConfirm, AlertInfo } from '../../components/Alerts/Alert';
import { api } from '../../services/api';
import isPermission from '../../services/permission';
import { arrayUf } from '../../data/uf';
import SelectCity from '../../components/Selects/SelectCity';
import { converterMoeda, displayMoney } from '../../services/displayValue';
import { dateEua, isDate } from '../../services/date';

export default function CadastroProfessor() {
    const navigate = useNavigate();
    const [searchParms] = useSearchParams();
    const [infoLogin] = useState(JSON.parse(sessionStorage.getItem('infoLogin')));
    const [id_escola_professor] = useState(infoLogin.id_escola_usuario || 0);
    const [openLoad, setOpenLoad] = useState(false);
    
    const [id_professor, setIdProfessor] = useState(null);
    const [nome_professor, setNomeProfessor] = useState("");
    const [cpf, setCpf] = useState("");
    const [endereco, setEndereco] = useState("");
    const [numero, setNumero] = useState("");
    const [bairro, setBairro] = useState("");
    const [complemento, setComplemento] = useState("");
    const [uf, setUf] = useState(infoLogin.uf || "PA");
    const [cidade, setCidade] = useState(infoLogin.cidade || "");
    const [cep, setCep] = useState(infoLogin.cep || "");
    const [telefone_1, setTelefone1] = useState("");
    const [telefone_2, setTelefone2] = useState("");
    const [email, setEmail] = useState("");
    const [senha, setSenha] = useState("");
    const [data_nascimento, setDataNascimento] = useState("");
    const [formacao, setFormacao] = useState("");
    const [adicional, setAdicional] = useState("");
    const [salario_base, setSalarioBase] = useState("");

    function setDataUser(data) {
        setIdProfessor(data.id_professor);
        setNomeProfessor(data.nome_professor);
        setCpf(data.cpf);
        setEndereco(data.endereco);
        setNumero(data.numero);
        setBairro(data.bairro);
        setComplemento(data.complemento);
        setUf(data.uf);
        setCidade(data.cidade);
        setCep(data.cep);
        setTelefone1(data.telefone_1);
        setTelefone2(data.telefone_2);
        setEmail(data.email);
        if(data.data_nascimento) setDataNascimento(dateEua(data.data_nascimento));
        setFormacao(data.formacao);
        setAdicional(data.adicional);
        setSalarioBase(displayMoney(data.salario_base));
    }

    async function loadData() {
        setOpenLoad(true);
        const id = searchParms.get('id');
        if(id){
            try {
                const data = {id_professor: id, id_escola_professor: infoLogin.id_escola_usuario};
                const response = await api.post(`/professor`, data);
                if(response && response.status === 200 && response.data){
                    if(response.data.error) AlertInfo("error", response.data.message);
                    else setDataUser(response.data[0]);
                }
            } catch (error) {
                
            }
        }
        setOpenLoad(false);
    }

    async function handleSave() {
        setOpenLoad(true);
        try {
            if(isPermission(6) || isPermission(7)){
                const data = {
                    id_professor, id_escola_professor, nome_professor, cpf, endereco, numero, bairro, complemento,
                    uf, cidade, cep, telefone_1, telefone_2, email, senha, formacao, adicional,
                    salario_base: converterMoeda(salario_base),
                    data_nascimento: isDate(data_nascimento)
                };
                const channel = id_professor ? `/updateprofessor` : `/createprofessor`;
                const response = await api.post(channel, data);
                let error = 'FALHA NA OPERAÇÃO';
                if(response && response.status === 200 && response.data){
                    if(response.data.error){
                        error = response.data.message;
                    }else{
                        setOpenLoad(false);
                        AlertInfo('success', 'Dados Gravados').then(() => {
                            navigate('/professores');
                        });
                        return;
                    }
                }
                AlertInfo('error', error);
            }
        } catch (error) {
            console.log(error);
            AlertInfo("error", "FALHA NA CONEXÃO");
        }
        setOpenLoad(false);
    }

    function handleDelete() {
        AlertConfirm('', "EXCLUIR PROFESSOR(A)?").then(async res => {
            if(res.isConfirmed){
                setOpenLoad(true);
                const response = await api.post(`/deleteprofessor`, { id_professor });
                if(response && response.status && response.data){
                    if(response.data.success){
                        AlertInfo("success", "PROFESSOR(A) REMOVIDO").then(() => {
                            navigate('/professores');
                        });
                    }else if(response.data.error){
                        AlertInfo("error", response.data.message);
                    }
                }else{
                    AlertInfo("error", "Falha na operação");
                }
                setOpenLoad(false);
            }
        });
    }

    useEffect(() => {
        loadData();
        // eslint-disable-next-line
    }, []);

    return(
        <Template menuActive="professores">
            <br/>
            <Paper elevation={3} style={{padding: 8}}>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <h2 style={{textAlign:'center'}}>DADOS DO PROFESSOR</h2>
                    </Grid>

                    <Grid item xs={12} sm={8}>
                        <Input
                            id="nome_professor"
                            label="NOME PROFESSOR(A)"
                            value={nome_professor}
                            setValue={setNomeProfessor}
                            next="cpf"
                        />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <Input
                            id="cpf"
                            label="CPF"
                            value={cpf}
                            setValue={setCpf}
                            next="endereco"
                            mask="cpf"
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Input
                            id="endereco"
                            label="ENDEREÇO"
                            value={endereco}
                            setValue={setEndereco}
                            next="numero"
                        />
                    </Grid>
                    <Grid item xs={12} sm={2}>
                        <Input
                            id="numero"
                            label="NUMERO"
                            value={numero}
                            setValue={setNumero}
                            next="bairro"
                        />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <Input
                            id="bairro"
                            label="BAIRRO"
                            value={bairro}
                            setValue={setBairro}
                            next="complemento"
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Input
                            id="complemento"
                            label="COMPLEMENTO"
                            value={complemento}
                            setValue={setComplemento}
                            next="telefone_1"
                        />
                    </Grid>
                    <Grid item xs={12} sm={2}>
                        <SelectSimple
                            label="UF"
                            value={uf}
                            setValue={setUf}
                            options={arrayUf}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <SelectCity
                            uf={uf}
                            cidade={cidade}
                            setCidade={setCidade}
                        />
                    </Grid>
                    <Grid item xs={12} sm={2}>
                        <Input
                            id="cep"
                            label="CEP"
                            value={cep}
                            setValue={setCep}
                            next="telefone_1"
                        />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <Input
                            id="telefone_1"
                            label="TELEFONE 1"
                            value={telefone_1}
                            setValue={setTelefone1}
                            mask="telefone"
                            next="telefone_2"
                        />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <Input
                            id="telefone_2"
                            label="TELEFONE 2"
                            value={telefone_2}
                            setValue={setTelefone2}
                            mask="telefone"
                            next="email"
                        />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <Input
                            id="email"
                            label="EMAIL"
                            value={email}
                            setValue={setEmail}
                            next="data_nascimento"
                        />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <Input
                            type="date"
                            id="data_nascimento"
                            label="DATA DE NASCIMENTO"
                            value={data_nascimento}
                            setValue={setDataNascimento}
                            upper={false}
                            next="formacao"
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <Input
                            id="salario_base"
                            label="SALARIO BASE"
                            value={salario_base}
                            setValue={setSalarioBase}
                            next="senha"
                        />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <Input
                            id="senha"
                            label="SENHA"
                            value={senha}
                            setValue={setSenha}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Input
                            id="formacao"
                            label="FORMAÇÃO"
                            value={formacao}
                            setValue={setFormacao}
                            next="adicional"
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Input
                            id="adicional"
                            label="ADICIONAL"
                            value={adicional}
                            setValue={setAdicional}
                            next="salario_base"
                        />
                    </Grid>
                    

                    {id_professor && isPermission(8) && (
                        <>
                            <Grid item xs={false} sm={10}></Grid>
                            <Grid item xs={false} sm={2}>
                                <Button 
                                    variant="contained"
                                    color='error'
                                    onClick={() => handleDelete()}
                                    fullWidth
                                >
                                    <FaTrash /> &nbsp; DELETAR
                                </Button>
                            </Grid>
                        </>
                    )}


                    <Grid item xs={false} sm={4}></Grid>
                    <Grid item xs={false} sm={4}>
                        <Button 
                            variant="contained"
                            color='success'
                            onClick={() => handleSave()}
                            fullWidth
                        >
                            SALVAR PROFESSOR(A)
                        </Button>
                    </Grid>
                    <Grid item xs={false} sm={4}></Grid>
                    
                </Grid>
            </Paper>

            <SimpleBackdrop open={openLoad} />
        </Template>
    );
}