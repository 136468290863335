import { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Fab from '@mui/material/Fab';
import SearchIcon from '@mui/icons-material/Search';
import Template from "../../template/Template";
import TableDataSimple from "../../components/Tables/TableDataSimple";
import SimpleBackdrop from '../../components/Loading/SimpleBackdrop';
import Input from '../../components/Inputs/Input';
import { AlertInfo } from '../../components/Alerts/Alert';
import { api } from '../../services/api';
import isPermission from '../../services/permission';
import { dateBrl } from '../../services/date';

import DrawerBuscaAlunos from './DrawerBuscaAlunos';

export default function Alunos() {
    const [openFilters, setOpenFilters] = useState(false);
    const [openLoad, setOpenLoad] = useState(false);
    const [nome_aluno, setNomeAluno] = useState("");
    const [allRows, setAllRows] = useState([]);
    const [rows, setRows] = useState([]);
    const navigate = useNavigate();
    const columns = [
        {
            id: 'matricula_aluno',
            numeric: false,
            label: 'MATRICULA',
        },
        {
            id: 'nome_aluno',
            numeric: false,
            disablePadding: false,
            label: 'NOME ALUNO',
        },
        {
            id: 'data_matricula',
            numeric: false,
            disablePadding: false,
            label: 'DATA MATRICULA',
            format: dateBrl
        },
        {
            id: 'nome_turma',
            numeric: false,
            disablePadding: false,
            label: 'TURMA',
        }
    ];

    function handleClick(row) {
        if(isPermission(22) && row.id_aluno){
            navigate(`/cadastroaluno?id=${row.id_aluno}`);
        }
    }

    async function loadData() {
        setOpenLoad(true);
        try {
            const response = await api.get(`/alunos`);
            if(response && response.status === 200 && response.data){
                if(response.data.error){
                    AlertInfo("error", response.data.message);
                }else{
                    setRows(response.data);
                    setAllRows(response.data);
                }
            }
        } catch (error) {
            console.log(error);
            AlertInfo("error", 'NÃO POSSIVEL BUSCAR DADOS');
        }
        setOpenLoad(false);
    }

    function searchAluno(event){
        if(event.key === 'Enter'){
            if(String(nome_aluno).length > 0){
                const filter = allRows.filter(a => String(a.nome_aluno).includes(nome_aluno));
                return setRows(filter);
            }
            setRows(allRows);
        }
    }

    useEffect(() => {
        loadData();
        // eslint-disable-next-line
    }, []);
    
    return(
        <Template menuActive="alunos">
            <SimpleBackdrop open={openLoad} />
            <DrawerBuscaAlunos
                open={openFilters}
                setOpen={setOpenFilters}
                allRows={allRows}
                setRows={setRows}
            />

            <Grid container spacing={1}>
                <Grid item xs={12} sm={2}>
                    {isPermission(20) && (
                        <Link to="/cadastroaluno">
                            <Button 
                                variant="contained"
                                fullWidth
                            >
                                NOVO ALUNO(A)
                            </Button>
                        </Link>
                    )}
                </Grid>
                <Grid item xs={false} sm={6}></Grid>
                <Grid item xs={12} sm={4}>
                    <div style={{display: 'flex', gap: 5}}>
                        <Input
                            label="Busca rapida (Nome do aluno)"
                            value={nome_aluno}
                            setValue={setNomeAluno}
                            onKeyUp={searchAluno}
                        />
                        <Fab 
                            size="small" 
                            color="primary" 
                            onClick={() => setOpenFilters(true)}
                        >
                            <SearchIcon />
                        </Fab>
                    </div>
                </Grid>
                <Grid item xs={12}>
                    <TableDataSimple
                        label="Lista de Alunos"
                        rows={rows}
                        columns={columns} 
                        handleClick={handleClick}
                    />
                </Grid>
            </Grid>

        </Template>
    );
}