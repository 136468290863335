import { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import Fab from '@mui/material/Fab';
import SearchIcon from '@mui/icons-material/Search';
import Button from '@mui/material/Button';
import Template from "../../template/Template";
import TableDataSimple from "../../components/Tables/TableDataSimple";
import SimpleBackdrop from '../../components/Loading/SimpleBackdrop';
import LabelAsaas from '../../components/Labels/LabelAsaas';
import Input from '../../components/Inputs/Input';
import { AlertInfo } from '../../components/Alerts/Alert';
import { api, asaas } from '../../services/api';
import isPermission from '../../services/permission';
import { converterMoeda, displayMoney, displayNumber2 } from '../../services/displayValue';
import { dateBrl, endMes, startMes } from '../../services/date';

import DrawerFiltroMensalidade from './DrawerFiltroMensalidade';
import ModalInfoParcela from './ModalInfoParcela';
import EmitirMensalidades from './EmitirMensalidades';
import ModalBuscaAluno from './ModalBuscaAluno';

export default function Mensalidades() {
    const [openLoad, setOpenLoad] = useState(false);
    const [openFilters, setOpenFilters] = useState(false);
    const [buscaRapida, setBuscaRapida] = useState("");
    const [rows, setRows] = useState([]);
    const [openModalEmitir, setOpenModalEmitir] = useState(false);
    const [openModalParcela, setOpenModalParcela] = useState(false);
    const [openModalBuscaAluno, setOpenModalBuscaAluno] = useState(false);
    const [infoParcela, setInfoParcela] = useState(null);
    const columns = [
        {
            id: 'nome_aluno',
            numeric: false,
            label: 'ALUNO(A)',
        },
        {
            id: 'responsavel',
            numeric: false,
            label: 'RESPONSAVEL',
        },
        {
            id: 'parcela',
            numeric: false,
            label: 'PARCELA',
            format: displayNumber2
        },
        {
            id: 'situacao',
            numeric: false,
            label: 'STATUS',
            format: () => "",
            component: LabelAsaas
        },
        {
            id: 'vencimento',
            numeric: false,
            label: 'VENCIMENTO',
            format: dateBrl
        },
        {
            id: 'pagamento',
            numeric: false,
            label: 'PAGAMENTO',
            format: (dt) => dt ? dateBrl(dt) : ""
        },
        {
            id: 'valor_parcela',
            numeric: true,
            label: 'VALOR',
            format: displayMoney
        }
    ];

    function handleClick(row) {
        setInfoParcela(row);
        setOpenModalParcela(true);
    }

    async function loadData(search = { start: startMes(), end: endMes() }) {
        setOpenLoad(true);
        try {
            const response = await api.post(`/parcelasperiodo`, search);
            if (response && response.status === 200 && response.data) {
                if (response.data.error) AlertInfo("error", response.data.message);
                else setRows(response.data);
            }
        } catch (error) {
            console.log(error);
            AlertInfo("error", 'NÃO POSSIVEL BUSCAR DADOS');
        }
        setOpenLoad(false);
    }

    async function loadInfoParcela(search = {}) {
        setOpenLoad(true);
        try {
            const response = await api.post(`/parcelas`, search);
            if (response && response.status === 200 && response.data) {
                if (response.data.error) AlertInfo("error", response.data.message);
                else setRows(response.data);
            }
        } catch (error) {
            console.log(error);
            AlertInfo("error", 'NÃO POSSIVEL BUSCAR DADOS');
        }
        setOpenLoad(false);
    }

    async function allConsult() {
        setOpenLoad(true);
        const infoLogin = JSON.parse(sessionStorage.getItem('infoLogin'));
        const rowsPending = rows.filter(r => r.situacao === 'PENDING' || r.situacao === 'OVERDUE');
        console.log(rowsPending.length);
        for (let index = 0; index < rowsPending.length; index++) {
            console.log(index);
            const element = rowsPending[index];
            const data = { apiKey: infoLogin.chave_api_key, id_transaction: element.id_transaction };
            const response = await asaas.post(`/infoParcela`, data);
            console.log(response.data);
            const taxa = converterMoeda(displayMoney(Number(response.data.value) - Number(response.data.netValue)));
            const dataApi = { id: { id_parcela: element.id_parcela }, data: { situacao: response.data.status, valor_liquido: response.data.netValue, taxa, pagamento: response.data.paymentDate } }
            const update = await api.post(`/updateparcela`, dataApi);
            console.log(update);

        }
        setOpenLoad(false);
    }

    function handleSearch(event){
        if(event.key === 'Enter'){
            if(String(buscaRapida).length > 0) setOpenModalBuscaAluno(true);
            else loadData();
        }
    }

    useEffect(() => {
        loadData();
        // eslint-disable-next-line
    }, []);

    return (
        <Template menuActive="mensalidades">
            <DrawerFiltroMensalidade
                open={openFilters}
                setOpen={setOpenFilters}
                loadData={loadData}
                loadInfoParcela={loadInfoParcela}
            />
            <ModalInfoParcela
                open={openModalParcela}
                setOpen={setOpenModalParcela}
                info={infoParcela}
                setInfo={setInfoParcela}
                reload={loadData}
            />
            <EmitirMensalidades
                open={openModalEmitir}
                setOpen={setOpenModalEmitir}
                loadData={loadData}
            />
            <ModalBuscaAluno
                open={openModalBuscaAluno}
                setOpen={setOpenModalBuscaAluno}
                busca={buscaRapida}
                loadInfoParcela={loadInfoParcela}
            />

            <Grid container spacing={1}>
                <Grid item xs={12} sm={2}>
                    {isPermission(61) && (
                        <Button
                            fullWidth
                            variant="contained"
                            onClick={() => setOpenModalEmitir(true)}
                        >
                            EMITIR MENSALIDADE
                        </Button>
                    )}
                </Grid>
                <Grid item xs={false} sm={4} />
                <Grid item xs={12} sm={2}>
                    {/* <Button
                        fullWidth
                        variant="contained"
                        onClick={() => allConsult()}
                        >
                        CONSULTAR TODOS
                    </Button> */}
                </Grid>
                <Grid item xs={12} sm={4}>
                    <div style={{display: 'flex', gap: 5}}>
                        <Input
                            label="Busca rapida (Nome do aluno ou responsavel)"
                            value={buscaRapida}
                            setValue={setBuscaRapida}
                            onKeyUp={handleSearch}
                        />
                        <Fab 
                            size="small" 
                            color="primary" 
                            onClick={() => setOpenFilters(true)}
                        >
                            <SearchIcon />
                        </Fab>
                    </div>
                </Grid>
                <Grid item xs={12}>
                    <TableDataSimple
                        label="Mensalidades"
                        rows={rows}
                        columns={columns}
                        handleClick={handleClick}
                        filterFunc={setOpenFilters}
                    />
                </Grid>
            </Grid>

            <SimpleBackdrop open={openLoad} />
        </Template>
    );
}