import { useState, useEffect } from "react";
import Modal from "../../components/Modal/Modal";
import TableDataSimple from "../../components/Tables/TableDataSimple";
import { api } from "../../services/api";
import { AlertInfo } from "../../components/Alerts/Alert";

export default function ModalAlunosTurma({open, setOpen, infoTurma}) {
    const [rows, setRows] = useState([]);
    const columns = [
        {
            id: 'matricula_aluno',
            label: 'MATRICULA'
        }, {
            id: 'nome_aluno',
            label: 'ALUNO'
        }
    ];
    
    function handleSelectName() {}

    async function loadData() {
        if(infoTurma && infoTurma.id_turma){
            const data = { id_turma_aluno: infoTurma.id_turma };
            const response = await api.post(`/aluno`, data);
            if(response && response.status === 200 && response.data){
                if(response.data.error){
                    AlertInfo('error', response.data.message);
                }else{
                    setRows(response.data);
                }
            }
        }
    }

    useEffect(() => {
        loadData();
    }, [infoTurma]);

    return (
        <Modal
            open={open}
            setOpen={setOpen}
            title={infoTurma ? infoTurma.nome_turma : "TURMA"}
            maxWidth="md"
        >
            <TableDataSimple
                rows={rows}
                columns={columns}
                clickFunc={handleSelectName}
            />
        </Modal>
    );
}