import Chip from '@mui/material/Chip';

export default function LabelAsaas({ item }) {
    const arrStatus = {
        'PENDING': { label: 'PENDENTE', color: "primary"},
        'OVERDUE': { label: 'EM ATRASO', color: "error"},  
        'RECEIVED': { label: 'RECEBIDO', color: "success"},
        'PAYMENT_OVERDUE': { label: 'RECEBIDO', color: "success"},
        'RECEIVED_IN_CASH': { label: 'RECEBIDO', color: "info"},
        'CANCELED': { label: 'CANCELADA', color: "warning" }
    };
    const status = arrStatus[item] ? arrStatus[item] : { label: 'OUTRO', color: "secondary" };

    return(
        <Chip label={status.label || ""} color={status.color || 'default'} />
    );
}