import { Paper } from "@mui/material";
import { useTheme } from "@mui/material";
import { FaBook, FaCropAlt, FaBriefcase, FaFileInvoiceDollar, FaUsers } from 'react-icons/fa';
import './cardbutton.css';

export default function CardButton({label, icon, ...rest}) {
    const theme = useTheme();
    const ListIcon = {
        book: FaBook,
        sala: FaCropAlt,
        work: FaBriefcase,
        fileDolar: FaFileInvoiceDollar,
        users: FaUsers
    };
    const ActiveIcon = ListIcon[icon];

    return(
        <div {...rest}>
            <Paper 
                elevation={8} 
                style={{backgroundColor: theme.palette.secondary.main, cursor: 'pointer'}} 
                sx={{
                    ':hover': {
                        boxShadow: 20
                    },
                }}
            >
                <div className="containerPaper">
                    <ActiveIcon size={60} />
                    <br />
                    <h1>{label}</h1>
                </div>
            </Paper>
        </div>
    );
}