import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';

import Login from './pages/Login/Login';
import Home from './pages/Home/Home';
import Usuarios from './pages/Usuarios/Usuarios';
import CadastroUsuario from './pages/Usuarios/CadastroUsuario';
import Professores from './pages/Professores/Professores';
import CadastroProfessor from './pages/Professores/CadastroProfessor';
import Funcionarios from './pages/Funcionarios/Funcionarios';
import CadastroFuncionario from './pages/Funcionarios/CadastroFuncionario';
import Alunos from './pages/Alunos/Alunos';
import CadastroAluno from './pages/Alunos/CadastroAluno';
import Definir from './pages/Definir/Definir';
import Turmas from './pages/Turmas/Turmas';
import Mensalidades from './pages/Mensalidades/Mensalidades';

const isAuthentication = () => {
    const infoLogin = sessionStorage.getItem('infoLogin') || null;
    if(infoLogin){
        return true;
    }else{
        return false;
    }
};

// NEXT FETURE
// const isAuthorized = () => {
//     const infoLogin = sessionStorage.getItem('infoLogin') || null;
//     if(infoLogin){
//         const permissions = String(infoLogin.permissoes_usuario).split(',');
//         //
//     }
// }

const PrivateRoute = (props) => {
    const { component: Component } = props;
    return isAuthentication() ? <Component/> : <Navigate replace to={"/login"} />
};

export default function MyRoutes() {
    return(
        <BrowserRouter>
            <Routes>
                <Route path="/login" element={<Login />} />
                <Route path="/usuarios" element={<PrivateRoute component={Usuarios} />} />
                <Route path="/cadastrousuario" element={<PrivateRoute component={CadastroUsuario} />} />
                <Route path="/professores" element={<PrivateRoute component={Professores} />} />
                <Route path="/cadastroprofessor" element={<PrivateRoute component={CadastroProfessor} />} />
                <Route path="/funcionarios" element={<PrivateRoute component={Funcionarios} />} />
                <Route path="/cadastrofuncionario" element={<PrivateRoute component={CadastroFuncionario} />} />
                <Route path="/alunos" element={<PrivateRoute component={Alunos} />} />
                <Route path="/cadastroaluno" element={<PrivateRoute component={CadastroAluno} />} />
                <Route path="/definir" element={<PrivateRoute component={Definir} />} />
                <Route path="/turmas" element={<PrivateRoute component={Turmas} />} />
                <Route path="/mensalidades" element={<PrivateRoute component={Mensalidades} />} />
                
                <Route path="/" element={<PrivateRoute component={Home} />} />
            </Routes>
        </BrowserRouter>
    );
}