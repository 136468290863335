import { useEffect, useState } from "react";
import moment from "moment";
import { ListItem } from "@mui/material";
import Button from '@mui/material/Button';
import DrawerApp from "../../components/Drawer/DrawerApp";
import SelectSimple from '../../components/Selects/SelectSimple';
import Input from '../../components/Inputs/Input';
import { startMes, endMes } from "../../services/date";
import Modal from "../../components/Modal/Modal";
import TableSimple from "../../components/Tables/TableSimple";
import { api } from '../../services/api';

export default function DrawerFiltroMensalidade({ open, setOpen, loadData, loadInfoParcela }) {
    const [busca, setBusca] = useState("1");
    const [mes, setMes] = useState(moment().format('YYYY-MM'));
    const [dataInicio, setDataInicio] = useState("");
    const [dataFinal, setDataFinal] = useState("");
    const [name, setName] = useState("");
    const [situacao, setSituacao] = useState("1");
    const [openModal, setOpenModal] = useState(false);
    const [rows, setRows] = useState([]);
    const [allRows, setAllRows] = useState([]);
    const columns = [
        {
            id: 'matricula_aluno',
            numeric: false,
            label: 'MATRICULA',
        },
        {
            id: 'nome_aluno',
            numeric: false,
            label: 'ALUNO(A)',
        },
        {
            id: 'responsavel',
            numeric: false,
            label: 'RESPONSAVEL',
        },
    ];

    function handleSearch() {
        if(busca === "3" || busca === "4"){
            const index = busca === "3" ? 'nome_aluno' : 'responsavel';
            const filterRows = allRows.filter(al => String(al[index]).includes(name));
            setRows(filterRows);
            setOpenModal(true);
        }else{
            let search = {};
            if (busca === "1") search = { start: startMes(mes), end: endMes(mes) };
            else if (busca === "2") search = { start: dataInicio, end: dataFinal };

            if (situacao !== "1") search = { ...search, and: { situacao } }

            loadData(search);
            setOpen(false);
        }
    }

    function handleSelectName(aluno) {
        const search = { id_aluno_parcela: aluno.id_aluno };
        loadInfoParcela(search);
        setOpenModal(false);
        setOpen(false);
    }

    async function loadAlunos() {
        try {
            const response = await api.get(`/alunos`);
            if(response && response.status === 200 && response.data){
                if(!response.data.error) setAllRows(response.data);
            }
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        loadAlunos();
    }, []);

    return (
        <DrawerApp
            open={open}
            setOpen={setOpen}
            title="FILTROS"
        >
            <Modal
                open={openModal}
                setOpen={setOpenModal}
                title={`PESQUISA POR: ${name}`}
                zIndex={9999}
                maxWidth="md"
            >
                <TableSimple
                    rows={rows}
                    columns={columns}
                    clickFunc={handleSelectName}
                />
            </Modal>
            <ListItem>
                <SelectSimple
                    label="BUSCA"
                    value={busca}
                    setValue={setBusca}
                    options={[
                        { value: "1", label: 'MÊS' },
                        { value: "2", label: 'PERIODO' },
                        { value: "3", label: 'ALUNO' },
                        { value: "4", label: 'RESPONSAVEL' },
                    ]}
                />
            </ListItem>

            {busca === "1" && (
                <ListItem>
                    <Input
                        type="month"
                        label="MÊS"
                        value={mes}
                        upper={false}
                        setValue={setMes}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </ListItem>
            )}
            {busca === "2" && (
                <>
                    <ListItem>
                        <Input
                            type="date"
                            label="DATA INCIO"
                            value={dataInicio}
                            setValue={setDataInicio}
                            upper={false}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </ListItem>
                    <ListItem>
                        <Input
                            type="date"
                            label="DATA FINAL"
                            value={dataFinal}
                            upper={false}
                            setValue={setDataFinal}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </ListItem>
                </>
            )}
            {(busca === "3" || busca === "4") && (
                <ListItem>
                    <Input
                        label="NOME"
                        value={name}
                        setValue={setName}
                        onKeyUp={e => {
                            if(e.key === 'Enter') handleSearch();
                        }}
                    />
                </ListItem>
            )}

            <ListItem>
                <SelectSimple
                    label="STATUS PARCELA"
                    value={situacao}
                    setValue={setSituacao}
                    options={[
                        { value: "1", label: 'TODAS' },
                        { value: "PENDING", label: 'PENDENTES' },
                        { value: "OVERDUE", label: 'EM ATRASO' },
                        { value: "RECEIVED", label: 'BOLETOS PAGOS' },
                        { value: "RECEIVED_IN_CASH", label: 'RECEBIDOS' },
                        { value: "CANCELED", label: 'CANCELADOS' },
                    ]}
                >
                </SelectSimple>
            </ListItem>

            <ListItem>
                <Button
                    variant="contained"
                    fullWidth
                    onClick={() => handleSearch()}
                >
                    FILTRAR
                </Button>
            </ListItem>
        </DrawerApp>
    );
}